import React, { useState, useCallback, useEffect } from 'react';

import AmountInWords from 'utils/AmountInWords';
import { getOrders } from 'redux/functions/admin';

//  React examples

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleDot,
  faCircleQuestion,
  faClockRotateLeft,
  faCloudArrowDown,
  faFileInvoice,
  faInfoCircle,
  faMagnifyingGlass,
  faPlusCircle,
  faSortDown,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import InvoiceDialog from './components/InvoiceDialog';
import { useAuthStore } from 'store/authStore';
import axios from 'axios';
import toast from 'react-hot-toast';

function Orders() {
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState({}); // [{}]
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState([{}]);
  const [keyword, setKeyword] = useState('');

  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const searched = (keyword) => (c) =>
    c?.orderId?.toLowerCase().includes(keyword);

  const fetchOrders = useCallback(async () => {
    getOrders(user.token)
      .then((res) => {
        setOrders(res?.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user?.token]);

  const handleClickOpen = (invoice) => () => {
    setInvoice(invoice);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeOrderStatus = async (e, orderId, orderStatus) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = {
        orderId,
        orderStatus,
      };
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/admin/change-order-status`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      fetchOrders();
      toast.success(res.data?.msg);
    } catch (error) {
      toast.error(error?.response?.data?.msg);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <>
      {/* Table Section */}
      <div className="max-w-[85rem]  py-10  lg:py-14 mx-auto">
        {/* Card */}
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
                {/* Header */}
                <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="hs-as-table-product-review-search"
                      className="sr-only"
                    >
                      Search By Invoice Number
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="hs-as-table-product-review-search"
                        name="hs-as-table-product-review-search"
                        className="block w-72 min-w-[16rem] px-3 py-2 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                        placeholder="Search Invoice Number"
                        onChange={handleSearchChange}
                      />
                      <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="w-4 h-4 text-gray-400"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <a
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 "
                        href="/"
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="w-4 h-4"
                        />
                        Create
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Header */}
                {/* Accordion */}
                <div className="border-b border-gray-200 hover:bg-gray-50 ">
                  <button
                    type="button"
                    className="flex items-center w-full gap-2 px-6 py-4 font-semibold text-gray-800 hs-collapse-toggle "
                    id="hs-basic-collapse"
                    data-hs-collapse="#hs-as-table-collapse"
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="hs-collapse-open:rotate-90 w-2.5 h-2.5"
                    />
                    Insights
                  </button>
                  <div
                    id="hs-as-table-collapse"
                    className="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
                    aria-labelledby="hs-basic-collapse"
                  >
                    <div className="px-6 pb-4">
                      <div className="flex items-center space-x-2">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                        />
                        <span className="text-sm text-gray-800 ">
                          There are no insights for this period.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Accordion */}
                {/* Table */}
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <div className="pt-1 text-xs font-semibold tracking-wide text-gray-800 uppercase">
                            Invoice number
                          </div>
                          <div className="hs-tooltip [--trigger:click]">
                            <div className="hs-tooltip-toggle">
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="w-3.5 h-3.5 hs-tooltip-toggle"
                              />
                              <span
                                className="absolute z-10 invisible inline-block max-w-xs px-2 py-1 text-xs font-medium text-white transition-opacity bg-gray-900 rounded-md shadow-sm opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible"
                                role="tooltip"
                              >
                                Invoice number is a unique identifier for
                                invoices. It is generated automatically and
                                cannot be changed.
                              </span>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                            Amount
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                            Status
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                            Customer
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-right" />
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 ">
                    {orders?.length > 0 ? (
                      <>
                        {orders?.filter(searched(keyword))?.map((order) => (
                          <tr
                            key={order?._id}
                            className="bg-white hover:bg-gray-50 "
                          >
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="flex flex-col ">
                                <div className="px-6 ">
                                  <span className="font-mono text-sm text-blue-600 ">
                                    {order?.orderId}
                                  </span>
                                </div>
                                <div className="px-6 pb-1">
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-gray-100 text-gray-600 ">
                                    <FontAwesomeIcon
                                      icon={faClockRotateLeft}
                                      className="w-2.5 h-2.5"
                                    />
                                    {moment(order?.createdAt).format(
                                      'MMM Do YYYY, h:mm:ss a'
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="block">
                                <div className="flex items-center px-6 py-2 ">
                                  <div className="text-sm text-gray-600 ">
                                    {(order?.orderDetails?.orderDetails?.totalAmount).toLocaleString(
                                      'en-US',
                                      {
                                        style: 'currency',
                                        currency: 'INR',
                                      }
                                    )}
                                  </div>
                                  <div className="hs-tooltip [--trigger:click] inline-flex items-center gap-1.5 py-0.5 px-2">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion}
                                      className="w-3 h-3 text-gray-800 hs-tooltip-toggle"
                                    />
                                    <span
                                      className="absolute z-10 invisible block px-2 py-1 text-xs font-medium text-white transition-opacity bg-gray-900 rounded-md shadow-sm opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible "
                                      role="tooltip"
                                    >
                                      <AmountInWords
                                        amount={
                                          order?.orderDetails?.orderDetails
                                            ?.totalAmount
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="block">
                                <div className="flex flex-col px-6 py-2">
                                  <div className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium  ">
                                    Payment Status:{' '}
                                    <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 ">
                                      <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className="w-2.5 h-2.5"
                                      />
                                      {order?.paymentStatus}
                                    </span>
                                  </div>
                                  <div className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium  ">
                                    Order Status:{' '}
                                    <span
                                      className={`inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium ${
                                        order?.orderDetails?.orderStatus ===
                                        'Not Processed'
                                          ? 'bg-gray-100 text-gray-800'
                                          : order?.orderDetails?.orderStatus ===
                                            'Processing'
                                          ? 'bg-yellow-100 text-yellow-800'
                                          : order?.orderDetails?.orderStatus ===
                                            'Dispatched'
                                          ? 'bg-blue-100 text-blue-800'
                                          : order?.orderDetails?.orderStatus ===
                                            'Completed'
                                          ? 'bg-green-100 text-green-800'
                                          : 'bg-red-100 text-red-800'
                                      } `}
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          order?.orderDetails?.orderStatus ===
                                          'Not Processed'
                                            ? faCircleDot
                                            : order?.orderDetails
                                                ?.orderStatus === 'Processing'
                                            ? faClockRotateLeft
                                            : order?.orderDetails
                                                ?.orderStatus === 'Dispatched'
                                            ? faTruckFast
                                            : order?.orderDetails
                                                ?.orderStatus === 'Completed'
                                            ? faCheckCircle
                                            : faBan
                                        }
                                        className="w-2.5 h-2.5"
                                      />
                                      {order?.orderDetails?.orderStatus}
                                    </span>
                                    <div className="relative inline-flex hs-dropdown">
                                      <div
                                        id="hs-dropdown-role-trigger"
                                        type="button"
                                        className="flex items-center justify-center w-3 h-3 text-gray-400 border border-gray-200 rounded hs-dropdown-toggle group-hover:bg-gray-200 "
                                      >
                                        <FontAwesomeIcon
                                          icon={faSortDown}
                                          className="w-2 h-2"
                                        />
                                      </div>
                                      <div
                                        className="hs-dropdown-menu z-50 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[10rem] bg-white shadow-md rounded-lg p-2 mt-2 "
                                        aria-labelledby="hs-dropdown-role-trigger"
                                      >
                                        <button
                                          onClick={(e) =>
                                            handleChangeOrderStatus(
                                              e,
                                              order?.orderDetails?.orderId,
                                              'Not Processed'
                                            )
                                          }
                                          className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                                        >
                                          Not Processed
                                        </button>
                                        <button
                                          onClick={(e) =>
                                            handleChangeOrderStatus(
                                              e,
                                              order?.orderDetails?.orderId,
                                              'Processing'
                                            )
                                          }
                                          className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-yellow-100 focus:ring-2 focus:ring-blue-500 "
                                        >
                                          Processing
                                        </button>
                                        <button
                                          onClick={(e) =>
                                            handleChangeOrderStatus(
                                              e,
                                              order?.orderDetails?.orderId,
                                              'Dispatched'
                                            )
                                          }
                                          className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-blue-100 focus:ring-2 focus:ring-blue-500 "
                                        >
                                          Dispatched
                                        </button>
                                        <button
                                          onClick={(e) =>
                                            handleChangeOrderStatus(
                                              e,
                                              order?.orderDetails?.orderId,
                                              'Completed'
                                            )
                                          }
                                          className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-green-100 focus:ring-2 focus:ring-blue-500 "
                                        >
                                          Completed
                                        </button>
                                        <button
                                          onClick={(e) =>
                                            handleChangeOrderStatus(
                                              e,
                                              order?.orderDetails?.orderId,
                                              'Cancelled'
                                            )
                                          }
                                          className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-red-100 focus:ring-2 focus:ring-blue-500 "
                                        >
                                          Cancelled
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="px-6 py-2">
                                <div className="flex items-center gap-x-3">
                                  <div className="grow">
                                    <span className="block text-sm font-semibold text-gray-800 ">
                                      {order?.paidBy?.name}
                                    </span>
                                    <span className="block text-sm text-gray-500">
                                      {order?.paidBy?.email}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="block">
                                <div className="px-6 py-1.5">
                                  <button
                                    type="button"
                                    onClick={handleClickOpen(order)}
                                    className="inline-flex items-center justify-center gap-2 px-2 py-1 text-sm font-medium text-gray-700 align-middle bg-white border rounded-md shadow-sm hover:bg-gray-50 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faFileInvoice}
                                      className="w-4 h-4"
                                    />
                                    View
                                  </button>
                                  <button className="inline-flex items-center justify-center gap-2 px-2 py-1 text-sm font-medium text-gray-700 align-middle ">
                                    <FontAwesomeIcon
                                      icon={faCloudArrowDown}
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td className="px-6 py-4 text-center" colSpan="6">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-16 h-16 text-gray-300"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 2a8 8 0 100 16 8 8 0 000-16zM7 9a1 1 0 112 0v3a1 1 0 11-2 0V9zm6 0a1 1 0 112 0v3a1 1 0 11-2 0V9z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="text-gray-500">No Orders Found</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* End Table */}
                {/* Footer */}
                <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                  <div>
                    <p className="text-sm text-gray-600 ">
                      <span className="font-semibold text-gray-800 ">
                        {orders?.filter(searched(keyword))?.length}
                      </span>{' '}
                      results
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="w-3 h-3"
                        />
                        Prev
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                      >
                        Next
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="w-3 h-3"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                {/* End Footer */}
              </div>
            </div>
          </div>
        </div>
        {/* End Card */}
      </div>
      {/* End Table Section */}
      {/* Modal */}

      <InvoiceDialog open={open} handleClose={handleClose} invoice={invoice} />
      {/* End Modal */}
    </>
  );
}

export default Orders;
