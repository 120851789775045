import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
//  React components
import SoftBox from 'components/SoftBox';
//  React examples
import Breadcrumbs from 'examples/Breadcrumbs';
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  // navbarIconButton,
  navbarMobileMenu,
} from 'examples/Navbars/DashboardNavbar/styles';
import { auth } from 'firebaseConfig';
import { useNavigate } from 'react-router-dom';
//  React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from 'context';

// Images
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faBoxesStacked,
  faMoneyBillTransfer,
  faRightFromBracket,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Avatar } from '@mui/material';
import { useAuthStore } from 'store/authStore';

function DashboardNavbar({ absolute, light, isMini }) {
  const { user, removeUser } = useAuthStore();
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const route = useLocation().pathname.split('/').slice(1);

  const logout = () => {
    auth.signOut();
    // dispatchUser({ type: 'LOGOUT', payload: null });
    removeUser();
    navigate('/', { replace: true });
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {window.innerWidth > 959 ? (
          <SoftBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          </SoftBox>
        ) : null}
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftBox pr={1} pt={0}>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                {miniSidenav ? (
                  <FontAwesomeIcon icon={faBars} className="text-dark" />
                ) : (
                  <FontAwesomeIcon icon={faBars} className="text-dark" />
                )}
              </IconButton>
            </SoftBox>
            <SoftBox>
              {user && user.token ? (
                <div className="relative hs-dropdown">
                  <button
                    id="hs-dropdown-custom-trigger"
                    type="button"
                    className="inline-flex items-center justify-center gap-2 py-1 pl-1 pr-3 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-full shadow-sm hs-dropdown-toggle hover:bg-gray-50 "
                  >
                    <Avatar
                      src={user?.picture}
                      alt={user?.name}
                      sx={{ width: 32, height: 32 }}
                    />
                    <span className="text-gray-600 font-medium truncate max-w-[7.5rem] ">
                      {user?.name}
                    </span>
                    <svg
                      className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                  <div
                    className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[15rem] bg-white shadow-md rounded-lg p-2 mt-2 "
                    aria-labelledby="hs-dropdown-custom-trigger"
                  >
                    <span
                      onClick={() => navigate('/panel/orders')}
                      className="cursor-pointer  flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                    >
                      {' '}
                      <FontAwesomeIcon
                        icon={faBoxesStacked}
                        className="text-dark"
                      />
                      Orders
                    </span>
                    <span
                      onClick={() => navigate('/panel/transactions')}
                      className="cursor-pointer  flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                    >
                      {' '}
                      <FontAwesomeIcon
                        icon={faMoneyBillTransfer}
                        className="text-dark"
                      />
                      Transactions
                    </span>
                    <span
                      onClick={() => navigate('/panel/users')}
                      className="cursor-pointer  flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                    >
                      {' '}
                      <FontAwesomeIcon icon={faUsers} className="text-dark" />
                      Users
                    </span>
                    <span
                      onClick={logout}
                      className="cursor-pointer flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                    >
                      <FontAwesomeIcon
                        icon={faRightFromBracket}
                        className="text-dark"
                      />
                      Logout
                    </span>
                  </div>
                </div>
              ) : null}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
