import axios from 'axios';

export const getWaterSchedules = async () =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/waterSchedules`);

export const getWaterSchedule = async (slug) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/waterSchedule/${slug}`);

export const removeWaterSchedule = async (slug, authtoken) =>
  await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/waterSchedule/${slug}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

export const updateWaterSchedule = async (slug, waterSchedule, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_BASE_URL}/waterSchedule/${slug}`,
    waterSchedule,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

export const createWaterSchedule = async (waterSchedule, authtoken) =>
  await axios.post(
    `${process.env.REACT_APP_BASE_URL}/waterSchedule`,
    waterSchedule,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
