import React, { useState, useCallback, useEffect } from 'react';
import { getCategories, removeCategory } from 'redux/functions/category';
import { getSubs } from 'redux/functions/sub';
import AddCategory from './Add';
import UpdateCategory from './Update';
//--Sub Category
import SubCategory from './SubCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faChevronRight,
  faChevronLeft,
  faMagnifyingGlass,
  faEllipsis,
  faTrash,
  faCheckCircle,
  faTriangleExclamation,
  faSortDown,
  faClockRotateLeft,
  faLink,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useAuthStore } from 'store/authStore';

const Categories = () => {
  const { user } = useAuthStore();
  const [categories, setCategories] = useState([]);
  const [subs, setSubs] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };

  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const categoriesResponse = await getCategories(user?.token);
      setCategories(categoriesResponse.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response.status === 400) {
        console.log(err.response.data?.errMsg);
      } else {
        console.error(err);
      }
    }
  }, [user?.token]);

  const handleRemove = (slug) => {
    if (window.confirm('Are you sure you want to delete this collection?')) {
      removeCategory(slug, user?.token)
        .then((res) => {
          fetchData();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            console.log(err.response.data?.errMsg);
          }
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="max-w-[85rem]  py-10  lg:py-14 mx-auto">
      {/* Card */}
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
              {/* Header */}
              <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                <div className="sm:col-span-1">
                  <label
                    htmlFor="hs-as-table-product-review-search"
                    className="sr-only"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="hs-as-table-product-review-search"
                      name="hs-as-table-product-review-search"
                      className="block w-full px-3 py-2 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="w-4 h-4 text-gray-400"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <AddCategory fetchData={fetchData} />
                  </div>
                </div>
              </div>
              {/* End Header */}
              {/* Table */}
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Collection Name
                        </span>
                        <div className="flex items-center justify-center w-5 h-5 text-gray-400 border border-gray-200 rounded group-hover:bg-gray-200 ">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="w-2.5 h-2.5"
                          />
                        </div>
                      </div>
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Sub Categories
                        </span>
                      </div>
                    </th> */}
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Slug
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Status
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-right" />
                  </tr>
                </thead>
                {loading ? (
                  <tbody className="divide-y divide-gray-200 ">
                    <tr>
                      <td className="px-6 py-4 text-center" colSpan="5">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="w-10 h-10 text-gray-300 animate-spin"
                          />
                          <p className="text-gray-500">
                            Getting Collections...
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="divide-y divide-gray-200 ">
                    {categories?.length > 0 ? (
                      <>
                        {categories?.filter(searched(keyword))?.map((cat) => (
                          <tr
                            key={cat?._id}
                            className="bg-white hover:bg-gray-50 "
                          >
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="flex flex-col ">
                                <div className="px-6 ">
                                  <span className="font-mono text-sm text-blue-600 ">
                                    {cat?.name}
                                  </span>
                                </div>
                                <div className="px-6 pb-1">
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-gray-100 text-gray-600 ">
                                    <FontAwesomeIcon
                                      icon={faClockRotateLeft}
                                      className="w-2.5 h-2.5"
                                    />
                                    {moment(cat?.createdAt).format(
                                      'MMM Do YYYY'
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                            {/* <td className="h-px w-80 min-w-[20rem]">
                              <div className="relative flex items-center">
                                <div className="px-6 py-2">
                                  {subs?.filter(
                                    (s) => s?.parent?._id === cat?._id
                                  )?.length > 0 ? (
                                    <div className="flex flex-col">
                                      <div className="flex items-center gap-1 text-sm text-gray-500">
                                        <span className="font-medium">
                                          {
                                            subs?.filter(
                                              (s) => s?.parent?._id === cat?._id
                                            )?.length
                                          }{' '}
                                          Sub Categories Found
                                        </span>
                                      </div>
                                      <span>
                                        {subs
                                          ?.filter(
                                            (s) => s?.parent?._id === cat?._id
                                          )
                                          ?.map((s) => (
                                            <span
                                              key={s?._id}
                                              className="inline-flex items-center gap-1.5 mx-1 py-1 px-2 rounded-md text-xs font-medium bg-gray-100 text-gray-800  "
                                            >
                                              {s?.name}
                                            </span>
                                          ))}
                                      </span>
                                    </div>
                                  ) : (
                                    <p className="text-sm font-medium text-gray-500">
                                      No Sub Categories Found
                                    </p>
                                  )}
                                </div>
                                <div className="relative inline-flex hs-dropdown">
                                  <div
                                    id="hs-dropdown-sub-cat-trigger"
                                    type="button"
                                    className="flex items-center justify-center w-3 h-3 text-gray-400 border border-gray-200 rounded hs-dropdown-toggle group-hover:bg-gray-200 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faSortDown}
                                      className="w-2 h-2"
                                    />
                                  </div>
                                  <div
                                    className="hs-dropdown-menu z-50 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[10rem] bg-white shadow-md rounded-lg p-2 mt-2 "
                                    aria-labelledby="hs-dropdown-sub-cat-trigger"
                                  >
                                    <div className="py-2 first:pt-0 last:pb-0">
                                      <span className="block px-3 py-2 text-xs font-medium text-gray-400 uppercase ">
                                        Actions
                                      </span>

                                      <SubCategory
                                        category={cat}
                                        subCategory={subs?.filter(
                                          (s) => s?.parent?._id === cat?._id
                                        )}
                                        fetchData={fetchData}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td> */}
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="relative z-10 block">
                                <div className="px-6 py-2">
                                  <span className="inline-flex items-center gap-1.5 py-1 px-2 rounded-md text-xs font-medium bg-gray-100 text-gray-800  ">
                                    <FontAwesomeIcon
                                      icon={faLink}
                                      className="w-2.5 h-2.5"
                                    />
                                    {cat?.slug}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="flex flex-col gap-2 px-6 py-3">
                                <div className="flex items-center gap-1.5">
                                  {cat?.status ? (
                                    <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 ">
                                      <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="w-2.5 h-2.5"
                                      />
                                      Active
                                    </span>
                                  ) : (
                                    <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-orange-100 text-orange-800 ">
                                      <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                        className="w-2.5 h-2.5"
                                      />
                                      Inactive
                                    </span>
                                  )}
                                  <div className="relative inline-flex hs-dropdown">
                                    <div
                                      id="hs-dropdown-status-trigger"
                                      type="button"
                                      className="flex items-center justify-center w-3 h-3 text-gray-400 border border-gray-200 rounded hs-dropdown-toggle group-hover:bg-gray-200 "
                                    >
                                      <FontAwesomeIcon
                                        icon={faSortDown}
                                        className="w-2 h-2"
                                      />
                                    </div>
                                    <div
                                      className="hs-dropdown-menu z-50 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[10rem] bg-white shadow-md rounded-lg p-2 mt-2 "
                                      aria-labelledby="hs-dropdown-status-trigger"
                                    >
                                      <button className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 ">
                                        Activate
                                      </button>
                                      <button className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 ">
                                        Deactivate
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="w-px h-px whitespace-nowrap">
                              <div className="px-6 py-2">
                                <div className="hs-dropdown relative inline-block [--placement:bottom-right]">
                                  <button
                                    id="hs-table-dropdown-1"
                                    type="button"
                                    className="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm   "
                                  >
                                    <FontAwesomeIcon
                                      icon={faEllipsis}
                                      className="w-4 h-4"
                                    />
                                  </button>
                                  <div
                                    className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden  divide-y divide-gray-200 min-w-[10rem] z-20 bg-white shadow-2xl rounded-lg p-2 mt-2  "
                                    aria-labelledby="hs-table-dropdown-1"
                                  >
                                    <div className="py-2 first:pt-0 last:pb-0">
                                      <span className="block px-3 py-2 text-xs font-medium text-gray-400 uppercase ">
                                        Actions
                                      </span>

                                      <UpdateCategory
                                        category={cat?.name}
                                        slug={cat?.slug}
                                        fetchData={fetchData}
                                      />
                                    </div>
                                    <div className="py-2 first:pt-0 last:pb-0">
                                      <button
                                        type="button"
                                        onClick={() => handleRemove(cat?.slug)}
                                        className="flex items-center w-full px-3 py-2 text-sm text-red-600 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="w-4 h-4"
                                        />
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan="6" className="px-6 py-3 text-center">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <AddCategory fetchData={fetchData} />
                            <p className="text-gray-500">
                              No Collections Found
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
              {/* End Table */}
              {/* Footer */}
              <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                <div>
                  <p className="text-sm text-gray-600 ">
                    <span className="font-semibold text-gray-800 ">
                      {categories?.filter(searched(keyword))?.length}
                    </span>{' '}
                    results
                  </p>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="w-3 h-3"
                      />
                      Prev
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      Next
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="w-3 h-3"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        </div>
      </div>
      {/* End Card */}
    </div>
  );
};

export default Categories;
