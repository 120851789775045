import axios from 'axios';

export const getCategories = async () =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/categories`);

export const getCategory = async (slug) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/category/${slug}`);

export const removeCategory = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/category/${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const updateCategory = async (slug, category, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_BASE_URL}/category/${slug}`,
    category,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

export const createCategory = async (category, authtoken) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/category`, category, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const getCategorySubs = async (_id) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/category/subs/${_id}`);
