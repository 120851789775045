import axios from 'axios';

export const getPotSizes = async () =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/potSizes`);

export const getPotSize = async (slug) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/potSize/${slug}`);

export const removePotSize = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/potSize/${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const updatePotSize = async (slug, potSize, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_BASE_URL}/potSize/${slug}`,
    potSize,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

export const createPotSize = async (potSize, authtoken) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/potSize`, potSize, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });
