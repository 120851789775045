import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCircleXmark,
  faClose,
  faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';
import brand from 'assets/logo.png';
export default function TransactionDialog({ open, handleClose, invoice }) {
  return (
    <Dialog maxWidth="xl" scroll="body" open={open} onClose={handleClose}>
      <DialogTitle className="flex items-center justify-end w-full ">
        <button
          type="button"
          onClick={handleClose}
          className="inline-flex items-center justify-end flex-shrink-0 float-right w-10 h-10 text-sm text-gray-500 transition-all rounded-md hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-900 "
          data-hs-overlay="#hs-bg-gray-on-hover-cards"
          data-hs-remove-element="#hs-ai-modal"
        >
          <span className="sr-only">Close</span>

          <FontAwesomeIcon icon={faCircleXmark} className="w-8 h-8" />
        </button>
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="w-full">
            {/* Card */}
            <div className="flex flex-col p-4 bg-white shadow-md sm:p-10 rounded-xl ">
              {/* Grid */}
              <div className="flex justify-between">
                <div>
                  <img
                    className="w-32 h-20"
                    src={brand}
                    alt="Your Adda "
                    width={80}
                    height={80}
                  />
                </div>
                {/* Col */}

                {/* Col */}
              </div>
              {/* End Grid */}
              {/* Grid */}
              <div className="text-center ">
                <h1 className="mt-4 text-2xl font-bold text-zinc-900">
                  Payment Status: {invoice?.paymentStatus}
                </h1>
              </div>
              <div className="py-8">
                <div className="grid gap-8">
                  <div>
                    <h2 className="text-xs font-bold uppercase text-zinc-400">
                      Shipping Address
                    </h2>
                    <p className="mt-2 text-sm font-medium text-zinc-600">
                      {
                        invoice?.orderDetails?.orderDetails?.shippingAddress
                          ?.receiverName
                      }
                      <br />
                      {
                        invoice?.orderDetails?.orderDetails?.shippingAddress
                          ?.mobileNumber
                      }
                    </p>
                    <p className="mt-3 text-sm font-medium text-zinc-600">
                      {
                        invoice?.orderDetails?.orderDetails?.shippingAddress
                          ?.flatOrHouse
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.shippingAddress
                          ?.areaOrStreet
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.shippingAddress
                          ?.landmark
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.shippingAddress
                          ?.city
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.shippingAddress
                          ?.pincode
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.shippingAddress
                          ?.state
                      }
                    </p>
                  </div>
                  <div>
                    <h2 className="text-xs font-bold uppercase text-zinc-400">
                      Billing Address
                    </h2>
                    <p className="mt-2 text-sm font-medium text-zinc-600">
                      {
                        invoice?.orderDetails?.orderDetails?.billingAddress
                          ?.receiverName
                      }
                      <br />
                      {
                        invoice?.orderDetails?.orderDetails?.billingAddress
                          ?.mobileNumber
                      }
                    </p>
                    <p className="mt-3 text-sm font-medium text-zinc-600">
                      {
                        invoice?.orderDetails?.orderDetails?.billingAddress
                          ?.flatOrHouse
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.billingAddress
                          ?.areaOrStreet
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.billingAddress
                          ?.landmark
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.billingAddress
                          ?.city
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.billingAddress
                          ?.pincode
                      }
                      ,
                      {
                        invoice?.orderDetails?.orderDetails?.billingAddress
                          ?.state
                      }
                    </p>
                  </div>

                  <div>
                    <h2 className="text-xs font-bold uppercase text-zinc-400">
                      Payment Info
                    </h2>
                    <p className="mt-2 text-sm font-medium text-zinc-600">
                      <strong>Order Id: </strong>{' '}
                      {invoice?.paymentDetails?.order_id}
                    </p>
                    <p className="text-sm font-medium mt-1s text-zinc-600">
                      <strong>Payment Method: </strong>{' '}
                      {invoice?.paymentDetails?.card_name} /{' '}
                      {invoice?.paymentDetails?.payment_mode}
                    </p>
                    <p className="mt-1 text-sm font-medium text-zinc-600">
                      <strong>Tracking Id :</strong>{' '}
                      {invoice?.paymentDetails?.tracking_id}
                      <br />
                      <strong>Bank Reference No :</strong>{' '}
                      {invoice?.paymentDetails?.bank_ref_no}
                      <br />
                      <strong>Transition Date:</strong>{' '}
                      {invoice?.paymentDetails?.trans_date}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5 sm:mt-10">
                <p className="text-sm text-gray-500">
                  If you have any questions, please login to your{' '}
                  <a
                    className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                    href="https://dashboard.ccavenue.com/jsp/merchant/merchantLogin.jsp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    CCAvenue account
                  </a>{' '}
                  .
                </p>
              </div>
            </div>
            {/* End Card */}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
