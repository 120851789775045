import axios from 'axios';

export const getColors = async () =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/colors`);

export const getColor = async (slug) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/color/${slug}`);

export const removeColor = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/color/${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const updateColor = async (slug, color, authtoken) =>
  await axios.put(`${process.env.REACT_APP_BASE_URL}/color/${slug}`, color, {
    headers: {
      authtoken,
    },
  });

export const createColor = async (color, authtoken) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/color`, color, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });
