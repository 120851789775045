import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
//  React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout';
import { Button } from '@nextui-org/react';
import { useAuthStore } from 'store/authStore';

function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useAuthStore();

  const submitHandler = async (e) => {
    try {
      setLoading(true);

      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: { 'Content-Type': 'application/json' },
      });

      const data = await res.json();

      if (data.error) {
        console.log('ERROR', data.error);
        if (data.error === 'CredentialsSignin') {
          toast.error('Email or password is incorrect');
        } else {
          toast.error(data.error);
        }
        setPassword('');
        return;
      } else {
        roleBasedRedirect(data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const roleBasedRedirect = (data) => {
    // check if intended
    if (location.state) {
      navigate(location.state.from);
    } else {
      if (data?.role === 'admin') {
        setUser(data);
        // dispatch({
        //   type: 'LOGGED_IN_USER',
        //   payload: {
        //     name: data?.name,
        //     email: data?.email,
        //     phone: data?.phone,
        //     picture: data?.picture,
        //     token: data?.token,
        //     role: data?.role,
        //     _id: data?.id,
        //   },
        // });

        navigate('/panel/dashboard');
        toast.success(`Welcome back ${data?.name}`);
      } else {
        toast.error('Please login with admin account');
      }
    }
  };

  useEffect(() => {
    if (user && user.token) {
      if (user.role === 'admin') {
        navigate('/panel/dashboard');
      }
    }
  }, [user]);

  return (
    <CoverLayout
      title="Welcome back"
      image="https://images.pexels.com/photos/797797/pexels-photo-797797.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
    >
      <SoftBox component="form" role="form">
        <SoftBox>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <Button
            color="primary"
            className="w-full"
            onPress={submitHandler}
            isLoading={loading}
            isDisabled={loading || !email || !password}
          >
            Sign In
          </Button>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{' '}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign Up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
