import { useState } from 'react';

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Checkbox from '@mui/material/Checkbox';

//  React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';

// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@nextui-org/react';

function SignUp() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');

  const submitHandler = async () => {
    setLoading(true);
    console.log('SUBMITTING', name, email, password);

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/register`,
        {
          method: 'POST',
          body: JSON.stringify({ name, email, password }),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      const data = await res.json();

      if (data.status === 'error') {
        console.log('ERROR', data.message);
        toast.error(data.message);
        return;
      } else {
        toast.success('Registration successful');
        navigate('/authentication/sign-in');
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <CoverLayout
      title="Sign Up!"
      image="https://images.pexels.com/photos/797797/pexels-photo-797797.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
    >
      {/* <SoftBox mb={1} textAlign="center">
        <SoftButton variant="outlined" color="dark">
          <svg width="30px" height="20px" viewBox="0 0 64 64" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(3.000000, 2.000000)" fillRule="nonzero">
                <path
                  d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                  fill="#4285F4"
                />
                <path
                  d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                  fill="#34A853"
                />
                <path
                  d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                  fill="#FBBC05"
                />
                <path
                  d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                  fill="#EB4335"
                />
              </g>
            </g>
          </svg>
          &nbsp;&nbsp;Continue with Google
        </SoftButton>
      </SoftBox> */}
      {/* <Separator /> */}
      <SoftBox pt={2} pb={3} px={3}>
        <SoftBox component="form" role="form">
          <SoftBox mb={2}>
            <SoftInput
              placeholder="Name"
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
          </SoftBox>

          <Button
            color="primary"
            className="w-full"
            onPress={submitHandler}
            isLoading={loading}
            isDisabled={loading || !name || !email || !password}
          >
            Sign up
          </Button>
          <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              Already have an account?&nbsp;
              <SoftTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="dark"
                fontWeight="bold"
                textGradient
              >
                Sign in
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignUp;
