import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';

const AdminRoute = () => {
  const { user } = useAuthStore();
  const [ok, setOk] = useState(false);

  // console.log('USER', user);

  const checkAdminStatus = async () => {
    if (user && user?.token) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/auth/current-admin`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        const data = await res.json();

        if (data.ok) {
          setOk(true);
        }
      } catch (err) {
        setOk(false);
      }
    } else {
      setOk(false);
    }
  };

  // useLayoutEffect(() => {
  //   checkAdminStatus();
  // }, [user]);

  return user?.role === 'admin' ? (
    <Outlet />
  ) : (
    <Navigate to="/authentication/sign-in" replace={true} />
  );
};

export default AdminRoute;
