import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleXmark,
  faDownload,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faFilter,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
const Reviews = () => {
  return (
    <div className="max-w-[85rem]  py-10  lg:py-14 mx-auto">
      {/* Card */}
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
              {/* Header */}
              <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                {/* Input */}
                <div className="sm:col-span-1">
                  <label
                    htmlFor="hs-as-table-product-review-search"
                    className="sr-only"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="hs-as-table-product-review-search"
                      name="hs-as-table-product-review-search"
                      className="block w-full px-3 py-2 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Search"
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Header */}
              {/* Table */}
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Product
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Reviewer
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Review
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Date
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Status
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 ">
                  {Array(1)
                    .fill()
                    .map((_, i) => (
                      <>
                        {' '}
                        <tr className="bg-white hover:bg-gray-50 ">
                          <td className="w-px h-px whitespace-nowrap">
                            <a className="block h-full p-6" href="/">
                              <div className="flex items-center gap-x-4">
                                <div>
                                  <span className="block text-sm font-semibold text-gray-800 ">
                                    Product Name
                                  </span>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <a className="block h-full p-6" href="/">
                              <div className="flex items-center gap-x-3">
                                <div className="grow">
                                  <span className="block text-sm font-semibold text-gray-800 ">
                                    Christina Bersh
                                  </span>
                                  <span className="block text-sm text-gray-500">
                                    christina@site.com
                                  </span>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td className="h-px w-72 min-w-[18rem]">
                            <a className="block h-full p-6" href="/">
                              <div className="flex mb-2 gap-x-1">
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="w-3 h-3 text-gray-800"
                                />
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="w-3 h-3 text-gray-800"
                                />
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="w-3 h-3 text-gray-800"
                                />
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="w-3 h-3 text-gray-800"
                                />
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="w-3 h-3 text-gray-800"
                                />
                              </div>
                              <span className="block text-sm font-semibold text-gray-800 ">
                                I just love it!
                              </span>
                              <span className="block text-sm text-gray-500">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Aliquam at ipsum eu nunc
                                commodo posuere et sit amet ligula.
                              </span>
                            </a>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <a className="block h-full p-6" href="/">
                              <span className="text-sm text-gray-600 ">
                                {new Date().toLocaleDateString()}
                              </span>
                            </a>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <a className="block h-full p-6" href="/">
                              <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 ">
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  className="w-2.5 h-2.5"
                                />
                                Published
                              </span>
                              {/* <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-red-100 text-red-800 ">
                                <FontAwesomeIcon
                                  icon={faCircleXmark}
                                  className="w-2.5 h-2.5"
                                />
                                Rejected
                              </span> */}
                            </a>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
              {/* End Table */}
              {/* Footer */}
              <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                <div className="max-w-sm space-y-3">
                  <select
                    className="block w-full px-3 py-2 text-sm border-gray-200 rounded-md pr-9 focus:border-blue-500 focus:ring-blue-500 "
                    defaultValue="1"
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </select>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="w-3 h-3"
                      />
                      Prev
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      Next
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="w-3 h-3"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        </div>
      </div>
      {/* End Card */}
    </div>
  );
};

export default Reviews;
