import React, { useState, useCallback, useEffect } from 'react';

import AmountInWords from 'utils/AmountInWords';
import { getOrders } from 'redux/functions/admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//  React examples

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleQuestion,
  faClockRotateLeft,
  faClose,
  faCloudArrowDown,
  faFileInvoice,
  faInfoCircle,
  faMagnifyingGlass,
  faPlusCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useAuthStore } from 'store/authStore';

function Invoices() {
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [keyword, setKeyword] = useState('');

  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const searched = (keyword) => (c) =>
    c?.paymentIntent?.OrderId?.toLowerCase().includes(keyword);

  const fetchOrders = useCallback(async () => {
    getOrders(user.token)
      .then((res) => {
        setOrders(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user?.token]);

  const handleClickOpen =
    ({ invoiceId }) =>
    () => {
      setInvoiceId(invoiceId);
      setOpen(true);
    };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <>
      {/* Table Section */}
      <div className="max-w-[85rem]  py-10  lg:py-14 mx-auto">
        {/* Card */}
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
                {/* Header */}
                <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="hs-as-table-product-review-search"
                      className="sr-only"
                    >
                      Search By Invoice Number
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="hs-as-table-product-review-search"
                        name="hs-as-table-product-review-search"
                        className="block w-72 min-w-[16rem] px-3 py-2 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                        placeholder="Search Invoice Number"
                        onChange={handleSearchChange}
                      />
                      <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="w-4 h-4 text-gray-400"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <a
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 "
                        href="/"
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="w-4 h-4"
                        />
                        Create
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Header */}
                {/* Accordion */}
                <div className="border-b border-gray-200 hover:bg-gray-50 ">
                  <button
                    type="button"
                    className="flex items-center w-full gap-2 px-6 py-4 font-semibold text-gray-800 hs-collapse-toggle "
                    id="hs-basic-collapse"
                    data-hs-collapse="#hs-as-table-collapse"
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="hs-collapse-open:rotate-90 w-2.5 h-2.5"
                    />
                    Insights
                  </button>
                  <div
                    id="hs-as-table-collapse"
                    className="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
                    aria-labelledby="hs-basic-collapse"
                  >
                    <div className="px-6 pb-4">
                      <div className="flex items-center space-x-2">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                        />
                        <span className="text-sm text-gray-800 ">
                          There are no insights for this period.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Accordion */}
                {/* Table */}
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <div className="pt-1 text-xs font-semibold tracking-wide text-gray-800 uppercase">
                            Invoice number
                          </div>
                          <div className="hs-tooltip [--trigger:click]">
                            <div className="hs-tooltip-toggle">
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="w-3.5 h-3.5 hs-tooltip-toggle"
                              />
                              <span
                                className="absolute z-10 invisible inline-block max-w-xs px-2 py-1 text-xs font-medium text-white transition-opacity bg-gray-900 rounded-md shadow-sm opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible"
                                role="tooltip"
                              >
                                Invoice number is a unique identifier for
                                invoices. It is generated automatically and
                                cannot be changed.
                              </span>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                            Amount
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                            Status
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                            Customer
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-right" />
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 ">
                    {orders?.length > 0 ? (
                      <>
                        {orders?.filter(searched(keyword))?.map((order) => (
                          <tr
                            key={order?._id}
                            className="bg-white hover:bg-gray-50 "
                          >
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="flex flex-col ">
                                <div className="px-6 ">
                                  <span className="font-mono text-sm text-blue-600 ">
                                    {order?.paymentIntent?.OrderId}
                                  </span>
                                </div>
                                <div className="px-6 pb-1">
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-gray-100 text-gray-600 ">
                                    <FontAwesomeIcon
                                      icon={faClockRotateLeft}
                                      className="w-2.5 h-2.5"
                                    />
                                    {moment(order?.createdAt).format(
                                      'MMM Do YYYY, h:mm:ss a'
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="block">
                                <div className="flex items-center px-6 py-2 ">
                                  <div className="text-sm text-gray-600 ">
                                    {(
                                      order?.paymentIntent?.order?.order
                                        ?.amount / 100
                                    ).toLocaleString('en-IN', {
                                      style: 'currency',
                                      currency: 'INR',
                                    })}
                                  </div>
                                  <div className="hs-tooltip [--trigger:click] inline-flex items-center gap-1.5 py-0.5 px-2">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion}
                                      className="w-3 h-3 text-gray-800 hs-tooltip-toggle"
                                    />
                                    <span
                                      className="absolute z-10 invisible block px-2 py-1 text-xs font-medium text-white transition-opacity bg-gray-900 rounded-md shadow-sm opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible "
                                      role="tooltip"
                                    >
                                      <AmountInWords
                                        amount={
                                          order?.paymentIntent?.order?.order
                                            ?.amount / 100
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="block">
                                <div className="px-6 py-2">
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 ">
                                    <FontAwesomeIcon
                                      icon={faCircleCheck}
                                      className="w-2.5 h-2.5"
                                    />
                                    {order?.paymentIntent?.order?.paymentStatus}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="px-6 py-2">
                                <div className="flex items-center gap-x-3">
                                  <div className="grow">
                                    <span className="block text-sm font-semibold text-gray-800 ">
                                      {order?.orderedBy?.name}
                                    </span>
                                    <span className="block text-sm text-gray-500">
                                      {order?.orderedBy?.email}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="block">
                                <div className="px-6 py-1.5">
                                  <button
                                    type="button"
                                    onClick={handleClickOpen({
                                      invoiceId: order?.paymentIntent?.OrderId,
                                    })}
                                    className="inline-flex items-center justify-center gap-2 px-2 py-1 text-sm font-medium text-gray-700 align-middle bg-white border rounded-md shadow-sm hover:bg-gray-50 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faFileInvoice}
                                      className="w-4 h-4"
                                    />
                                    View
                                  </button>
                                  <button className="inline-flex items-center justify-center gap-2 px-2 py-1 text-sm font-medium text-gray-700 align-middle ">
                                    <FontAwesomeIcon
                                      icon={faCloudArrowDown}
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td className="px-6 py-4 text-center" colSpan="6">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-16 h-16 text-gray-300"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 2a8 8 0 100 16 8 8 0 000-16zM7 9a1 1 0 112 0v3a1 1 0 11-2 0V9zm6 0a1 1 0 112 0v3a1 1 0 11-2 0V9z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="text-gray-500">No Orders Found</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* End Table */}
                {/* Footer */}
                <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                  <div>
                    <p className="text-sm text-gray-600 ">
                      <span className="font-semibold text-gray-800 ">
                        {orders?.filter(searched(keyword))?.length}
                      </span>{' '}
                      results
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="w-3 h-3"
                        />
                        Prev
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                      >
                        Next
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="w-3 h-3"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                {/* End Footer */}
              </div>
            </div>
          </div>
        </div>
        {/* End Card */}
      </div>
      {/* End Table Section */}
      {/* Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="flex items-center justify-between">
          <span>
            Invoice Id <span className="text-blue-500">{invoiceId}</span>{' '}
          </span>
          <button
            type="button"
            onClick={handleClose}
            className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-sm text-gray-500 transition-all rounded-md hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-900 "
            data-hs-overlay="#hs-bg-gray-on-hover-cards"
            data-hs-remove-element="#hs-ai-modal"
          >
            <span className="sr-only">Close</span>

            <FontAwesomeIcon icon={faClose} className="w-4 h-4" />
          </button>
        </DialogTitle>
        <DialogContent>
          <div className="relative flex flex-col bg-white shadow-lg rounded-xl ">
            <div className="relative overflow-hidden min-h-[8rem] bg-gray-900 text-center rounded-t-xl">
              {/* SVG Background Element */}
              <figure className="absolute inset-x-0 bottom-0">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1920 100.1"
                >
                  <path
                    fill="currentColor"
                    className="fill-white "
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                  />
                </svg>
              </figure>
              {/* End SVG Background Element */}
            </div>
            <div className="relative z-10 -mt-12">
              {/* Icon */}
              <span className="mx-auto flex justify-center items-center w-[62px] h-[62px] rounded-full border border-gray-200 bg-white text-gray-700 shadow-sm   ">
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  className="w-6 h-6 text-gray-800"
                />
              </span>
              {/* End Icon */}
            </div>
            {/* Body */}
            <div className="p-4 overflow-y-auto sm:p-7">
              <div className="text-center">
                <h3 className="text-lg font-semibold text-gray-800 ">
                  Invoice from Preline
                </h3>
                <p className="text-sm text-gray-500">
                  InvoiceId <span className="text-blue-500">{invoiceId}</span>
                </p>
              </div>
              {/* Grid */}
              <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-3">
                <div>
                  <span className="block text-xs text-gray-500 uppercase">
                    Amount paid:
                  </span>
                  <span className="block text-sm font-medium text-gray-800 ">
                    $316.8
                  </span>
                </div>
                {/* End Col */}
                <div>
                  <span className="block text-xs text-gray-500 uppercase">
                    Date paid:
                  </span>
                  <span className="block text-sm font-medium text-gray-800 ">
                    April 22, 2020
                  </span>
                </div>
                {/* End Col */}
                <div>
                  <span className="block text-xs text-gray-500 uppercase">
                    Payment method:
                  </span>
                  <div className="flex items-center gap-x-2">
                    <svg
                      className="w-5 h-5"
                      width={400}
                      height={248}
                      viewBox="0 0 400 248"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <path
                          d="M254 220.8H146V26.4H254V220.8Z"
                          fill="#FF5F00"
                        />
                        <path
                          d="M152.8 123.6C152.8 84.2 171.2 49 200 26.4C178.2 9.2 151.4 0 123.6 0C55.4 0 0 55.4 0 123.6C0 191.8 55.4 247.2 123.6 247.2C151.4 247.2 178.2 238 200 220.8C171.2 198.2 152.8 163 152.8 123.6Z"
                          fill="#EB001B"
                        />
                        <path
                          d="M400 123.6C400 191.8 344.6 247.2 276.4 247.2C248.6 247.2 221.8 238 200 220.8C228.8 198.2 247.2 163 247.2 123.6C247.2 84.2 228.8 49 200 26.4C221.8 9.2 248.6 0 276.4 0C344.6 0 400 55.4 400 123.6Z"
                          fill="#F79E1B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width={400} height="247.2" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="block text-sm font-medium text-gray-800 ">
                      •••• 4242
                    </span>
                  </div>
                </div>
                {/* End Col */}
              </div>
              {/* End Grid */}
              <div className="mt-5 sm:mt-10">
                <h4 className="text-xs font-semibold text-gray-800 uppercase ">
                  Summary
                </h4>
                <ul className="flex flex-col mt-3">
                  <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                    <div className="flex items-center justify-between w-full">
                      <span>Payment to Front</span>
                      <span>$264.00</span>
                    </div>
                  </li>
                  <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                    <div className="flex items-center justify-between w-full">
                      <span>Tax fee</span>
                      <span>$52.8</span>
                    </div>
                  </li>
                  <li className="inline-flex items-center px-4 py-3 -mt-px text-sm font-semibold text-gray-800 border gap-x-2 bg-gray-50 first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                    <div className="flex items-center justify-between w-full">
                      <span>Amount paid</span>
                      <span>$316.8</span>
                    </div>
                  </li>
                </ul>
              </div>

              {/* End Buttons */}
              <div className="mt-5 sm:mt-10">
                <p className="text-sm text-gray-500">
                  If you have any questions, please reach out to{' '}
                  <a
                    className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                    href="https://support.shiprocket.in/support/home"
                    target="_blank"
                    rel="noreferrer"
                  >
                    support.shiprocket.in
                  </a>{' '}
                  or login to your{' '}
                  <a
                    className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                    href="https://app.shiprocket.in/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Shiprocket account
                  </a>
                  .
                </p>
              </div>
            </div>
            {/* End Body */}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
            // onClick={handleClose}
          >
            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
            Invoice PDF
          </button>
          <button className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ">
            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
              <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg>
            Print
          </button>
        </DialogActions>
      </Dialog>

      {/* End Modal */}
    </>
  );
}

export default Invoices;
