import axios from 'axios';

export const createProduct = async (product, authtoken) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/product`, product, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const getProductsByCount = async (count) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/products/${count}`);

export const removeProduct = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const getProduct = async (slug) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/${slug}`);

export const updateProduct = async (slug, product, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_BASE_URL}/product/${slug}`,
    product,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

export const getProducts = async (sort, order, page) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/products`, {
    sort,
    order,
    page,
  });
export const getHotProducts = async (sort, order, limit) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/hot-sale-products`, {
    sort,
    order,
    limit,
  });

export const getProductsCount = async () =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/products/total`);

export const productStar = async (productId, star, review, time, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_BASE_URL}/product/star/${productId}`,
    { star, review, time },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

export const getRelated = async (productId) =>
  await axios.get(
    `${process.env.REACT_APP_BASE_URL}/product/related/${productId}`
  );

export const fetchProductsByFilter = async (arg) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/search/filters`, arg);
