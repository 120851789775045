import { useState, useEffect, useCallback } from 'react';
import {
  faIndianRupeeSign,
  faInfoCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreatableSelect from 'react-select/creatable';
import { Tooltip } from '@mui/material';

const ProductVarients = ({ varients, setVarients }) => {
  const [color, setColor] = useState(
    varients?.map((v) => v?.color).filter((v) => v) || []
  );
  const [size, setSize] = useState(
    varients?.map((v) => v?.size).filter((v) => v) || []
  );

  const createCombinations = useCallback(() => {
    const combinations = [];

    if (varients?.length > 0) {
      varients?.forEach((v) => {
        if (size?.length > 0 && color?.length > 0) {
          size?.forEach((s) => {
            color?.forEach((c) => {
              combinations.push({
                size: s,
                color: c,
                colorCode: v?.colorCode,
                price: v?.price,
                discountPrice: v?.discountPrice,
                quantity: v?.quantity,
                sku: v?.sku,
              });
            });
          });
        } else if (size?.length > 0) {
          size?.forEach((s) => {
            combinations.push({
              size: s,
              color: v?.color,
              colorCode: v?.colorCode,
              price: v?.price,
              discountPrice: v?.discountPrice,
              quantity: v?.quantity,
              sku: v?.sku,
            });
          });
        } else if (color?.length > 0) {
          color?.forEach((c) => {
            combinations.push({
              size: v?.size,
              color: c,
              colorCode: v?.colorCode,
              price: v?.price,
              discountPrice: v?.discountPrice,
              quantity: v?.quantity,
              sku: v?.sku,
            });
          });
        } else {
          combinations.push({
            size: v?.size,
            color: v?.color,
            colorCode: v?.colorCode,
            price: v?.price,
            discountPrice: v?.discountPrice,
            quantity: v?.quantity,
            sku: v?.sku,
          });
        }
      });
    } else {
      if (color.length === 0 && size.length === 0) {
        const newVariant = {
          size: null,
          color: null,
          colorCode: null,
          price: '',
          discountPrice: '',
          quantity: '',
          sku: '',
        };
        combinations.push(newVariant);
      } else if (color.length === 0) {
        for (const sizeOption of size) {
          const newVariant = {
            size: sizeOption,
            color: null,
            colorCode: null,
            price: '',
            discountPrice: '',
            quantity: '',
            sku: '',
          };
          combinations.push(newVariant);
        }
      } else if (size.length === 0) {
        for (const colorOption of color) {
          const newVariant = {
            size: null,
            color: colorOption,
            colorCode: '',
            price: '',
            discountPrice: '',
            quantity: '',
            sku: '',
          };
          combinations.push(newVariant);
        }
      } else {
        for (const colorOption of color) {
          for (const sizeOption of size) {
            const newVariant = {
              size: sizeOption,
              color: colorOption,
              colorCode: '',
              price: '',
              discountPrice: '',
              quantity: '',
              sku: '',
            };
            combinations.push(newVariant);
          }
        }
      }
    }

    setVarients(combinations);
  }, [size, color]);

  useEffect(() => {
    createCombinations();
    // if no varient is present then remove the varients from local storage
    if (varients?.length === 0) {
      localStorage.removeItem('varients');
    }
  }, [size, color, createCombinations]);

  const handleInputChange = (index, field, value) => {
    const newVarientData = [...varients];
    newVarientData[index][field] = value;
    setVarients(newVarientData);
    // update local storage
    // localStorage.setItem('varients', JSON.stringify(newVarientData));
    console.log(newVarientData);
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-2 gap-x-10">
        <CreatableSelect
          className="relative block w-full -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
          id="af-product-subcategory"
          placeholder="Create Size"
          isMulti
          name="Size"
          styles={{
            control: (base) => ({
              ...base,
              borderColor: '#fff',
              width: '100%',
              '&:hover': {
                borderColor: '#fff',
              },
            }),
          }}
          onChange={(e) => {
            const newSize = e?.map((sub) => sub?.value) || [];
            setSize(newSize);

            // Update local storage
            const updatedVarients = varients.map((v) => {
              if (v.size && newSize.includes(v.size)) {
                v.size = newSize.find((size) => size === v.size);
              }
              return v;
            });

            localStorage.setItem('varients', JSON.stringify(updatedVarients));
          }}
          value={size?.map((s) => ({ value: s, label: s }))}
        />

        <CreatableSelect
          className="relative block w-full -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
          id="af-product-subcategory"
          placeholder="Create Color"
          isMulti
          name="Color"
          styles={{
            control: (base) => ({
              ...base,
              borderColor: '#fff',
              width: '100%',
              '&:hover': {
                borderColor: '#fff',
              },
            }),
          }}
          onChange={(e) => {
            setColor(e?.map((sub) => sub?.value));
          }}
          value={color?.map((s) => ({ value: s, label: s }))}
        />
      </div>

      {size?.length > 0 || color?.length > 0 ? (
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden border rounded-lg shadow ">
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      {size?.length > 0 && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase whitespace-nowrap"
                        >
                          Size
                          <Tooltip title="Size is used to display the size in the product page. Example: Small, Medium, Large">
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="inline-flex w-3 h-3 mx-1 text-gray-500"
                            />
                          </Tooltip>
                        </th>
                      )}
                      {color?.length > 0 && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase whitespace-nowrap"
                        >
                          Color{' '}
                          <Tooltip title="Color is used to display the color in the product page. Example: Red">
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="inline-flex w-3 h-3 mx-1 text-gray-500"
                            />
                          </Tooltip>
                        </th>
                      )}
                      {color?.length > 0 && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase whitespace-nowrap "
                        >
                          Color Code{' '}
                          <Tooltip title="Color Code is used to display the color in the product page. Example: #2145F3">
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="inline-flex w-3 h-3 mx-1 text-gray-500"
                            />
                          </Tooltip>
                        </th>
                      )}
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase whitespace-nowrap"
                      >
                        <FontAwesomeIcon
                          icon={faIndianRupeeSign}
                          className="inline-flex w-3 h-3 text-gray-400"
                        />{' '}
                        Price
                        <Tooltip title="Price is used to display the price in the product page. Example: ₹1000">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="inline-flex w-3 h-3 mx-1 text-gray-500"
                          />
                        </Tooltip>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase "
                      >
                        <FontAwesomeIcon
                          icon={faIndianRupeeSign}
                          className="w-3 h-3 text-gray-400"
                        />{' '}
                        Discount Price
                        <Tooltip title="Discount Price is used to display the discounted price in the product page. Example: ₹800. Leave it blank if there is no discount">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="inline-flex w-3 h-3 mx-1 text-gray-500"
                          />
                        </Tooltip>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase whitespace-nowrap"
                      >
                        Quantity
                        <Tooltip title="Quantity will be used to keep track of the stock. Example: 10">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="inline-flex w-3 h-3 mx-1 text-gray-500"
                          />
                        </Tooltip>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase whitespace-nowrap"
                      >
                        SKU
                        <Tooltip title="SKU will be used to identify the product in the inventory and while shipping. Example: SKU-1234">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="inline-flex w-3 h-3 mx-1 text-gray-500"
                          />
                        </Tooltip>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-right text-gray-500 uppercase "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 min-w-5xl ">
                    {varients?.map((v, i) => (
                      <tr key={i}>
                        {size?.length > 0 && (
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap ">
                            {v?.size}
                          </td>
                        )}
                        {color?.length > 0 && (
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap ">
                            {v?.color}
                          </td>
                        )}
                        {color?.length > 0 && (
                          <td className="px-6 min-w-[12rem] py-4 text-sm text-gray-800 whitespace-nowrap">
                            <input
                              id={`colorCode-${i}`}
                              type="text"
                              name="colorCode"
                              value={v?.colorCode}
                              onChange={(e) =>
                                handleInputChange(
                                  i,
                                  'colorCode',
                                  e.target.value
                                )
                              }
                              className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                              placeholder="Color Code"
                            />
                          </td>
                        )}
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap min-w-[12rem]  ">
                          <input
                            id={`price-${i}`}
                            type="number"
                            name="price"
                            value={v?.price}
                            onChange={(e) => {
                              handleInputChange(i, 'price', e.target.value);
                            }}
                            className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                            placeholder="Price"
                          />
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap min-w-[12rem] ">
                          <input
                            id={`discountPrice-${i}`}
                            type="number"
                            name="discountPrice"
                            value={v?.discountPrice}
                            onChange={(e) => {
                              handleInputChange(
                                i,
                                'discountPrice',
                                e.target.value
                              );
                            }}
                            className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                            placeholder="Discount Price"
                          />
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap min-w-[12rem] ">
                          <input
                            id={`quantity-${i}`}
                            type="number"
                            name="quantity"
                            value={v?.quantity}
                            onChange={(e) => {
                              handleInputChange(i, 'quantity', e.target.value);
                            }}
                            className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                            placeholder="Quantity"
                          />
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap min-w-[12rem] ">
                          <input
                            id={`sku-${i}`}
                            type="text"
                            name="sku"
                            value={v?.sku}
                            onChange={(e) => {
                              handleInputChange(i, 'sku', e.target.value);
                            }}
                            className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                            placeholder="SKU"
                          />
                        </td>
                        <td
                          onClick={() => {
                            const newVarientData = [...varients];
                            newVarientData.splice(i, 1);
                            setVarients(newVarientData);
                          }}
                          className="px-6 py-4 text-sm font-medium text-center whitespace-nowrap"
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="text-red-500 hover:text-red-700"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductVarients;
