import axios from 'axios';

export const createBlog = async (blog, authtoken) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/blog`, blog, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const getBlogsByCount = async (count) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/blogs/${count}`);

export const removeBlog = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/blog/${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const getBlogs = async (sort, order, page) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/blogs`, {
    sort,
    order,
    page,
  });

export const getBlog = async (slug) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/blog/${slug}`);

export const updateBlog = async (slug, blog, authtoken) =>
  await axios.put(`${process.env.REACT_APP_BASE_URL}/blog/${slug}`, blog, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });
