import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCircleXmark,
  faClose,
  faFileInvoice,
  faSpinner,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import brand from 'assets/logo.png';
import { useAuthStore } from 'store/authStore';
import toast from 'react-hot-toast';
import { useState } from 'react';
export default function InvoiceDialog({ open, handleClose, invoice }) {
  const { user } = useAuthStore();
  const [openShipment, setOpenShipment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: 0,
    length: 0,
    breadth: 0,
    weight: 0,
  });

  const handleClickOpenShipment = () => {
    setOpenShipment(true);
  };

  const handleCloseShipment = () => {
    setOpenShipment(false);
  };
  const handleCreateShipment = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/shipping/create-order/${invoice?.orderId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify(dimensions),
        }
      );
      const data = await response.json();
      console.log(data);
      toast.success(
        'Shipment Created Successfully, Please check your shiprocket dashboard!'
      );
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong, Please try again');
    } finally {
      setLoading(false);
      handleCloseShipment();
      handleClose();
      setDimensions({
        height: 0,
        length: 0,
        breadth: 0,
        weight: 0,
      });
    }
  };

  return (
    <>
      <Dialog open={openShipment} onClose={handleCloseShipment}>
        <DialogTitle>Enter the dimensions of the shipment</DialogTitle>
        <DialogContent className="space-y-5">
          <DialogContentText>
            Please enter the dimensions of the shipment in cm and weight in kg
            (minimum length, breadth and height should be 0.5cm and weight
            should be greater than 0kg)
          </DialogContentText>
          <input
            type="number"
            autoFocus
            name="length"
            value={dimensions.name}
            onChange={(e) =>
              setDimensions({ ...dimensions, length: e.target.value })
            }
            className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm pr-11 sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
            placeholder="Enter Length(cm)"
          />
          <input
            type="number"
            name="breadth"
            value={dimensions.breadth}
            onChange={(e) =>
              setDimensions({ ...dimensions, breadth: e.target.value })
            }
            className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm pr-11 sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
            placeholder="Enter Breadth(cm)"
          />
          <input
            type="number"
            name="height"
            value={dimensions.height}
            onChange={(e) =>
              setDimensions({ ...dimensions, height: e.target.value })
            }
            className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm pr-11 sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
            placeholder="Enter Height(cm)"
          />
          <input
            type="number"
            name="weight"
            value={dimensions.weight}
            onChange={(e) =>
              setDimensions({ ...dimensions, weight: e.target.value })
            }
            className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm pr-11 sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
            placeholder="Enter Weight(kg)"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShipment}>Cancel</Button>
          <Button onClick={handleCreateShipment}>
            {loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="w-4 h-4 mr-2 -ml-1 animate-spin"
              />
            ) : (
              'Create Shipment'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="xl" scroll="body" open={open} onClose={handleClose}>
        <DialogTitle className="flex items-center justify-end w-full ">
          <button
            type="button"
            onClick={handleClose}
            className="inline-flex items-center justify-end flex-shrink-0 float-right w-10 h-10 text-sm text-gray-500 transition-all rounded-md hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-900 "
            data-hs-overlay="#hs-bg-gray-on-hover-cards"
            data-hs-remove-element="#hs-ai-modal"
          >
            <span className="sr-only">Close</span>

            <FontAwesomeIcon icon={faCircleXmark} className="w-8 h-8" />
          </button>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="w-full">
              {/* Card */}
              <div className="flex flex-col p-4 bg-white shadow-md sm:p-10 rounded-xl ">
                {/* Grid */}
                <div className="flex justify-between">
                  <div>
                    <img
                      className="w-32 h-20"
                      src={brand}
                      alt="Your Adda "
                      width={80}
                      height={80}
                    />
                  </div>
                  {/* Col */}
                  <div className="text-right">
                    <h2 className="text-2xl font-semibold text-gray-800 md:text-3xl ">
                      Invoice
                    </h2>
                    <span className="block mt-1 text-gray-500">
                      {invoice?.orderId}
                    </span>
                    <address className="max-w-sm mt-4 not-italic text-gray-800 ">
                      C2/14 ground floor Shantiniketan society near Shreyas
                      garden Ghatkopar West L.B.S. marg Mumbai 400086.
                    </address>
                  </div>
                  {/* Col */}
                </div>
                {/* End Grid */}
                {/* Grid */}
                <div className="text-center ">
                  <h1 className="mt-4 text-2xl font-bold text-zinc-900">
                    Payment Status: {invoice?.paymentStatus}
                  </h1>
                </div>
                <div className="py-8">
                  <div className="grid gap-8">
                    <div>
                      <h2 className="text-xs font-bold uppercase text-zinc-400">
                        Shipping Address
                      </h2>
                      <p className="mt-2 text-sm font-medium text-zinc-600">
                        {
                          invoice?.orderDetails?.orderDetails?.shippingAddress
                            ?.receiverName
                        }
                        <br />
                        {
                          invoice?.orderDetails?.orderDetails?.shippingAddress
                            ?.mobileNumber
                        }
                      </p>
                      <p className="mt-3 text-sm font-medium text-zinc-600">
                        {
                          invoice?.orderDetails?.orderDetails?.shippingAddress
                            ?.flatOrHouse
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.shippingAddress
                            ?.areaOrStreet
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.shippingAddress
                            ?.landmark
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.shippingAddress
                            ?.city
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.shippingAddress
                            ?.pincode
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.shippingAddress
                            ?.state
                        }
                      </p>
                    </div>
                    <div>
                      <h2 className="text-xs font-bold uppercase text-zinc-400">
                        Billing Address
                      </h2>
                      <p className="mt-2 text-sm font-medium text-zinc-600">
                        {
                          invoice?.orderDetails?.orderDetails?.billingAddress
                            ?.receiverName
                        }
                        <br />
                        {
                          invoice?.orderDetails?.orderDetails?.billingAddress
                            ?.mobileNumber
                        }
                      </p>
                      <p className="mt-3 text-sm font-medium text-zinc-600">
                        {
                          invoice?.orderDetails?.orderDetails?.billingAddress
                            ?.flatOrHouse
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.billingAddress
                            ?.areaOrStreet
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.billingAddress
                            ?.landmark
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.billingAddress
                            ?.city
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.billingAddress
                            ?.pincode
                        }
                        ,
                        {
                          invoice?.orderDetails?.orderDetails?.billingAddress
                            ?.state
                        }
                      </p>
                    </div>

                    <div>
                      <h2 className="text-xs font-bold uppercase text-zinc-400">
                        Payment Info
                      </h2>
                      <p className="mt-2 text-sm font-medium text-zinc-600">
                        {invoice?.paymentDetails?.card_name} /{' '}
                        {invoice?.paymentDetails?.payment_mode}
                      </p>
                      <p className="mt-1 text-sm font-medium text-zinc-600">
                        Tracking Id : {invoice?.paymentDetails?.tracking_id}
                        <br />
                        Bank Reference No :{' '}
                        {invoice?.paymentDetails?.bank_ref_no}
                        <br />
                        Transition Date: {invoice?.paymentDetails?.trans_date}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="py-8 border-t border-solid border-zinc-200">
                  <h2 className="text-xs font-bold uppercase text-zinc-400">
                    Order Items
                  </h2>

                  <div className="flow-root mt-8">
                    <ul className="-mb-7 -mt-7">
                      {invoice?.orderDetails?.orderDetails?.cart?.map(
                        (item, index) => (
                          <li
                            key={index}
                            className="flex items-stretch justify-between py-7"
                          >
                            <div className="flex items-stretch">
                              <div>
                                <img
                                  src={item?.image}
                                  className="object-cover w-20 h-20 align-middle rounded-lg overflow-clip"
                                  alt=""
                                />
                              </div>

                              <div className="flex flex-col justify-between ml-5 w-44">
                                <p className="flex-grow text-sm font-bold text-zinc-900">
                                  {item?.title}
                                </p>
                                <p className="mt-1.5 text-sm font-medium text-zinc-500">
                                  {item?.quantity} x{' '}
                                  {item?.price?.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'INR',
                                  })}
                                </p>
                              </div>
                            </div>

                            <div className="ml-auto">
                              <p className="text-sm font-bold text-right text-zinc-900">
                                {(item?.price * item?.quantity)?.toLocaleString(
                                  'en-US',
                                  {
                                    style: 'currency',
                                    currency: 'INR',
                                  }
                                )}
                              </p>
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="py-8 border-t border-solid border-zinc-200">
                  <ul>
                    <li className="flex items-center justify-between mt-4">
                      <p className="text-sm font-medium text-zinc-900">Total</p>
                      <p className="text-sm font-bold text-zinc-900">
                        {invoice?.orderDetails?.orderDetails?.totalAmount?.toLocaleString(
                          'en-US',
                          {
                            style: 'currency',
                            currency: 'INR',
                          }
                        )}
                      </p>
                    </li>
                  </ul>
                </div>
                {/* End Flex */}
                <div className="mt-8 sm:mt-12">
                  <h4 className="text-lg font-semibold text-gray-800 ">
                    Thank you!
                  </h4>
                  <p className="text-gray-500">
                    If you have any questions concerning this invoice, use the
                    following contact information:
                  </p>
                  <div className="mt-2">
                    <a
                      href="mailto:youraddaa@gmail.com"
                      className="block text-sm font-medium text-gray-800 "
                    >
                      youraddaa@gmail.com
                    </a>
                    <p className="block text-sm font-medium text-gray-800 ">
                      +1 (062) 109-9222
                    </p>
                  </div>
                </div>
                <p className="mt-5 text-sm text-gray-500">
                  © {new Date().getFullYear()} Your Adda. All rights reserved.
                </p>
                <div className="mt-5 sm:mt-10">
                  <p className="text-sm text-gray-500">
                    If you have any questions, please reach out to{' '}
                    <a
                      className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                      href="https://support.shiprocket.in/support/home"
                      target="_blank"
                      rel="noreferrer"
                    >
                      support.shiprocket.in
                    </a>{' '}
                    or login to your{' '}
                    <a
                      className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                      href="https://app.shiprocket.in/login"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Shiprocket account
                    </a>
                    .
                  </p>
                </div>
              </div>
              {/* End Card */}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
            // onClick={handleClose}
          >
            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
            Invoice PDF
          </button>
          <button
            onClick={handleClickOpenShipment}
            className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 "
          >
            <FontAwesomeIcon icon={faTruckFast} className="w-4 h-4" />
            Create Shipment
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
