import AdminRoute from 'utils/AdminRoute';
import { Navigate } from 'react-router-dom';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import Dashboard from 'layouts/dashboard';
import Tables from 'layouts/tables';
import Billing from 'layouts/billing';
import Profile from 'layouts/profile';
import Blogs from 'layouts/blogs';
import Coupons from 'layouts/coupons';
import ProductForm from 'layouts/products/productForm';
import Reviews from 'layouts/reviews';
import Transactions from 'layouts/transactions';
import Orders from 'layouts/orders';
import Users from 'layouts/users';
import Categories from 'layouts/categories';
import Colors from 'layouts/colors';
import Maintenance from 'layouts/maintenance';
import PotSize from 'layouts/potSize';
import WaterSchedule from 'layouts/waterSchedule';
import Products from 'layouts/products';
import SignIn from 'layouts/authentication/sign-in';
import SignUp from 'layouts/authentication/sign-up';
import Gifting from 'layouts/gifting';

//  React icons

import CustomerSupport from 'examples/Icons/CustomerSupport';
import CreditCard from 'examples/Icons/CreditCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBox,
  faBoxesStacked,
  faChartLine,
  faMoneyBillTransfer,
  faShapes,
  faStar,
  faTicket,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { faSlack, faUnity } from '@fortawesome/free-brands-svg-icons';
import Invoices from 'layouts/invoices';
export const routes = [
  {
    path: '/panel',
    element: <AdminRoute />,
    children: [
      {
        path: '',
        element: <DashboardLayout />,
        children: [
          { path: '', element: <Navigate to="dashboard" replace /> },
          { path: '*', element: <Navigate to="dashboard" replace /> },
          {
            path: 'dashboard',
            element: <Dashboard />,
          },
          {
            path: 'orders',
            element: <Orders />,
          },
          {
            path: 'invoices',
            element: <Invoices />,
          },
          {
            path: 'transactions',
            element: <Transactions />,
            component: <Transactions />,
          },
          { path: 'products', element: <Products /> },
          { path: 'gifting', element: <Gifting /> },
          { path: 'products/add-product', element: <ProductForm /> },
          { path: 'products/edit-product/:id', element: <ProductForm /> },
          { path: 'blogs', element: <Blogs /> },
          { path: 'category', element: <Categories /> },
          { path: 'colors', element: <Colors /> },
          { path: 'maintenance', element: <Maintenance /> },
          { path: 'pot-size', element: <PotSize /> },
          { path: 'water-schedule', element: <WaterSchedule /> },
          { path: 'coupons', element: <Coupons /> },
          { path: 'file-manager', element: <Tables /> },
          { path: 'billing', element: <Billing /> },
          { path: 'reviews', element: <Reviews /> },
          { path: 'users', element: <Users /> },
          { path: 'profile', element: <Profile /> },
        ],
      },
    ],
  },
  {
    path: '',
    children: [
      { path: '', element: <Navigate to="/authentication/sign-in" /> },
      { path: '/authentication/sign-in', element: <SignIn /> },
      { path: '/authentication/sign-up', element: <SignUp /> },
    ],
  },
];

export const sidenavRoutes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    route: 'panel/dashboard',
    icon: <FontAwesomeIcon icon={faChartLine} className="text-[12px]" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  { type: 'title', title: 'Manage Orders', key: 'manage-orders' },
  {
    type: 'collapse',
    name: 'Orders',
    key: 'orders',
    route: 'panel/orders',
    icon: <FontAwesomeIcon icon={faBoxesStacked} className="text-[12px]" />,
    component: <Orders />,
    noCollapse: true,
  },

  {
    type: 'collapse',
    name: 'Transactions',
    key: 'transactions',
    route: 'panel/transactions',
    icon: (
      <FontAwesomeIcon icon={faMoneyBillTransfer} className="text-[12px]" />
    ),
    component: <Transactions />,
    noCollapse: true,
  },
  { type: 'title', title: 'Manage Products', key: 'manage-products' },
  {
    type: 'collapse',
    name: 'Products',
    key: 'products',
    route: 'panel/products',
    icon: <FontAwesomeIcon icon={faBox} className="text-[12px]" />,
    component: <Tables />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Gifting',
    key: 'gifting',
    route: 'panel/gifting',
    icon: <FontAwesomeIcon icon={faBox} className="text-[12px]" />,
    component: <Tables />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Coupons',
    key: 'coupons',
    route: 'panel/coupons',
    icon: <FontAwesomeIcon icon={faTicket} className="text-[12px]" />,
    component: <Coupons />,
    noCollapse: true,
  },
  { type: 'title', title: 'Manage Filters', key: 'manage-filters' },
  {
    type: 'collapse',
    name: 'Collections',
    key: 'categories',
    route: 'panel/category',
    icon: <FontAwesomeIcon icon={faSlack} className="text-[12px]" />,
    component: <Categories />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Color',
    key: 'color',
    route: 'panel/colors',
    icon: <FontAwesomeIcon icon={faSlack} className="text-[12px]" />,
    component: <Categories />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Maintenance',
    key: 'maintenance',
    route: 'panel/maintenance',
    icon: <FontAwesomeIcon icon={faSlack} className="text-[12px]" />,
    component: <Categories />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Pot Size',
    key: 'pot-size',
    route: 'panel/pot-size',
    icon: <FontAwesomeIcon icon={faSlack} className="text-[12px]" />,
    component: <Categories />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Water Schedule',
    key: 'water-schedule',
    route: 'panel/water-schedule',
    icon: <FontAwesomeIcon icon={faSlack} className="text-[12px]" />,
    component: <Categories />,
    noCollapse: true,
  },

  { type: 'title', title: 'Manage Blogs', key: 'manage-blogs' },
  {
    type: 'collapse',
    name: 'Blogs',
    key: 'blogs',
    route: 'panel/blogs',
    icon: <FontAwesomeIcon icon={faShapes} className="text-[12px]" />,
    component: <Blogs />,
    noCollapse: true,
  },

  // {
  //   type: 'collapse',
  //   name: 'Billing',
  //   key: 'billing',
  //   route: 'panel/billing',
  //   icon: <CreditCard size="12px" />,
  //   component: <Billing />,
  //   noCollapse: true,
  // },

  { type: 'title', title: 'Manage Users', key: 'manage-users' },

  {
    type: 'collapse',
    name: 'Users',
    key: 'users',
    route: 'panel/users',
    icon: <FontAwesomeIcon icon={faUsers} className="text-[12px]" />,
    component: <Users />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Reviews',
    key: 'reviews',
    route: 'panel/reviews',
    icon: <FontAwesomeIcon icon={faStar} className="text-[12px]" />,
    component: <Reviews />,
    noCollapse: true,
  },
  // {
  //   type: 'collapse',
  //   name: 'Profile',
  //   key: 'profile',
  //   route: 'panel/profile',
  //   icon: <CustomerSupport size="12px" />,
  //   component: <Profile />,
  //   noCollapse: true,
  // },
];
