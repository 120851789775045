import { useEffect } from 'react';

// react-router-dom components
import { useLocation, Outlet } from 'react-router-dom';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
//  React components
import SoftBox from 'components/SoftBox';

//  React context
import { useSoftUIController, setLayout } from 'context';

function DashboardLayout() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname, dispatch]);

  return (
    <SoftBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: 'relative',

        [breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <DashboardNavbar />
      <Outlet />
    </SoftBox>
  );
}

export default DashboardLayout;
