import React, { useState, useEffect } from 'react';

const AmountInWords = ({ amount }) => {
  const [amountInWords, setAmountInWords] = useState('');

  const number2text = (value) => {
    const fraction = Math.round(frac(value) * 100);
    let f_text = '';

    if (fraction > 0) {
      f_text = `AND ${convert_number(fraction)} PAISE`;
    }

    return `${convert_number(value)} RUPEE ${f_text} ONLY`;
  };

  const frac = (f) => f % 1;

  const convert_number = (number) => {
    if (number < 0 || number > 999999999) {
      return 'NUMBER OUT OF RANGE!';
    }

    const Gn = Math.floor(number / 10000000); /* Crore */
    number -= Gn * 10000000;
    const kn = Math.floor(number / 100000); /* lakhs */
    number -= kn * 100000;
    const Hn = Math.floor(number / 1000); /* thousand */
    number -= Hn * 1000;
    const Dn = Math.floor(number / 100); /* Tens (deca) */
    number = number % 100; /* Ones */
    const tn = Math.floor(number / 10);
    const one = Math.floor(number % 10);
    let res = '';

    if (Gn > 0) {
      res += `${convert_number(Gn)} CRORE`;
    }
    if (kn > 0) {
      res += `${res === '' ? '' : ' '}${convert_number(kn)} LAKH`;
    }
    if (Hn > 0) {
      res += `${res === '' ? '' : ' '}${convert_number(Hn)} THOUSAND`;
    }

    if (Dn) {
      res += `${res === '' ? '' : ' '}${convert_number(Dn)} HUNDRED`;
    }

    const ones = [
      '',
      'ONE',
      'TWO',
      'THREE',
      'FOUR',
      'FIVE',
      'SIX',
      'SEVEN',
      'EIGHT',
      'NINE',
      'TEN',
      'ELEVEN',
      'TWELVE',
      'THIRTEEN',
      'FOURTEEN',
      'FIFTEEN',
      'SIXTEEN',
      'SEVENTEEN',
      'EIGHTEEN',
      'NINETEEN',
    ];
    const tens = [
      '',
      '',
      'TWENTY',
      'THIRTY',
      'FORTY',
      'FIFTY',
      'SIXTY',
      'SEVENTY',
      'EIGHTY',
      'NINETY',
    ];

    if (tn > 0 || one > 0) {
      if (res !== '') {
        res += ' AND ';
      }
      if (tn < 2) {
        res += ones[tn * 10 + one];
      } else {
        res += tens[tn];
        if (one > 0) {
          res += `-${ones[one]}`;
        }
      }
    }

    if (res === '') {
      res = 'zero';
    }
    return res;
  };

  useEffect(() => {
    setAmountInWords(number2text(amount));
  }, [amount]);

  return <>{amountInWords}</>;
};

export default AmountInWords;
