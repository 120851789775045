// @mui material components
import Grid from '@mui/material/Grid';

//  React components
import SoftBox from 'components/SoftBox';

//  React examples

import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';

// Dashboard layout components
import BuildByDevelopers from 'layouts/dashboard/components/BuildByDevelopers';
import WorkWithTheRockets from 'layouts/dashboard/components/WorkWithTheRockets';
import Projects from 'layouts/dashboard/components/Projects';
import OrderOverview from 'layouts/dashboard/components/OrderOverview';
import Visitors from 'layouts/dashboard/components/Visitors';

// Data

function Dashboard() {
  return (
    <>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <h2 className="pb-5 text-2xl font-semibold">This month,</h2>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'revenue' }}
                count="₹24,680"
                percentage={{ color: 'success', text: '+55%' }}
                icon={{ color: 'info', component: 'paid' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'users' }}
                count="50"
                percentage={{ color: 'success', text: '+3%' }}
                icon={{ color: 'info', component: 'public' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'new users' }}
                count="12"
                percentage={{ color: 'error', text: '-2%' }}
                icon={{ color: 'info', component: 'emoji_events' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'orders' }}
                count="100"
                percentage={{ color: 'success', text: '+5%' }}
                icon={{
                  color: 'info',
                  component: 'shopping_cart',
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title="active users"
                description={
                  <>
                    (<strong>+23%</strong>) than last week
                  </>
                }
                chart={chart}
                items={items}
              />
            </Grid> */}
            {/* <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Sales Overview"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox
                      fontSize={size.lg}
                      color="success"
                      mb={0.3}
                      mr={0.5}
                      lineHeight={0}
                    >
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography
                      variant="button"
                      color="text"
                      fontWeight="medium"
                    >
                      4% more{' '}
                      <SoftTypography
                        variant="button"
                        color="text"
                        fontWeight="regular"
                      >
                        in 2021
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                height="20.25rem"
                chart={gradientLineChartData}
              />
            </Grid> */}
          </Grid>
        </SoftBox>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Visitors />
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default Dashboard;
