import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import moment from 'moment';
//  React examples
import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleQuestion,
  faClockRotateLeft,
  faDownload,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faFilter,
  faMagnifyingGlass,
  faShield,
  faSortDown,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { listUsers } from 'redux/functions/admin';
import { useAuthStore } from 'store/authStore';
import axios from 'axios';

function Users() {
  const { user } = useAuthStore();
  const [users, setUsers] = useState([]);
  const [keyword, setKeyword] = useState('');

  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };

  const fetchUsers = useCallback(async () => {
    listUsers(user?.token)
      .then((res) => {
        setUsers(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        toast.error(err.response.data?.errMsg);
        console.log(err.response.data?.errMsg);
      });
  }, []);

  const handleChangeUserRole = async (e, userId, role) => {
    e.preventDefault();
    try {
      const data = { userId, role };
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/admin/change-role`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      toast.success(res.data?.msg);
      fetchUsers();
    } catch (error) {
      toast.error(error.response.data?.errMsg);
      console.log(error.response.data?.errMsg);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);

  return (
    <div className="max-w-[85rem]  py-10  lg:py-14 mx-auto">
      {/* Card */}
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
              {/* Header */}
              <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                <div className="sm:col-span-1">
                  <label
                    htmlFor="hs-as-table-product-review-search"
                    className="sr-only"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="hs-as-table-product-review-search"
                      name="hs-as-table-product-review-search"
                      className="block w-full px-3 py-2 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="w-4 h-4 text-gray-400"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {' '}
                  <div className="inline-flex gap-x-2"></div>
                </div>
              </div>
              {/* End Header */}
              {/* Table */}
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Name
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Role
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Status
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Created
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-right" />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 ">
                  {users?.length > 0 ? (
                    <>
                      {users?.filter(searched(keyword)).map((user) => (
                        <tr key={user?._id}>
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <div className="grow">
                                  <span className="block text-sm font-semibold text-gray-800 ">
                                    {user?.name}
                                  </span>
                                  <span className="block text-sm text-gray-500">
                                    {user?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="h-px w-72 whitespace-nowrap">
                            <div className="flex flex-col px-6 py-3">
                              <div className="flex items-center gap-2">
                                {user?.role === 'admin' ? (
                                  <span className="flex items-center gap-1 text-sm font-semibold text-gray-800 capitalize ">
                                    <span className="sr-only">Admin</span>
                                    Admin
                                    <FontAwesomeIcon
                                      icon={faShield}
                                      className="w-3 h-3 text-indigo-400"
                                    />
                                  </span>
                                ) : (
                                  <span className="block text-sm font-semibold text-gray-800 capitalize ">
                                    {user?.role}
                                  </span>
                                )}
                                <div className="relative inline-flex hs-dropdown">
                                  <div
                                    id="hs-dropdown-role-trigger"
                                    type="button"
                                    className="flex items-center justify-center w-3 h-3 text-gray-400 border border-gray-200 rounded hs-dropdown-toggle group-hover:bg-gray-200 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faSortDown}
                                      className="w-2 h-2"
                                    />
                                  </div>
                                  <div
                                    className="hs-dropdown-menu z-50 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[10rem] bg-white shadow-md rounded-lg p-2 mt-2 "
                                    aria-labelledby="hs-dropdown-role-trigger"
                                  >
                                    <button
                                      onClick={(e) =>
                                        handleChangeUserRole(
                                          e,
                                          user?._id,
                                          'customer'
                                        )
                                      }
                                      className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                                    >
                                      Customer
                                    </button>
                                    <button
                                      onClick={(e) =>
                                        handleChangeUserRole(
                                          e,
                                          user?._id,
                                          'admin'
                                        )
                                      }
                                      className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                                    >
                                      Admin
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <span className="block text-sm text-gray-500">
                                {user?.uid}
                              </span>
                            </div>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="flex flex-col gap-2 px-6 py-3">
                              <div className="flex items-center gap-1.5">
                                {user?.status === 'active' ? (
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 ">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      className="w-2.5 h-2.5"
                                    />
                                    Active
                                  </span>
                                ) : user?.status === 'deleted' ? (
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-red-100 text-red-800 ">
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="w-2.5 h-2.5"
                                    />
                                    Deleted
                                  </span>
                                ) : (
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-orange-100 text-orange-800 ">
                                    <FontAwesomeIcon
                                      icon={faTriangleExclamation}
                                      className="w-2.5 h-2.5"
                                    />
                                    Inactive
                                  </span>
                                )}
                                <div className="relative inline-flex hs-dropdown">
                                  <div
                                    id="hs-dropdown-status-trigger"
                                    type="button"
                                    className="flex items-center justify-center w-3 h-3 text-gray-400 border border-gray-200 rounded hs-dropdown-toggle group-hover:bg-gray-200 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faSortDown}
                                      className="w-2 h-2"
                                    />
                                  </div>
                                  <div
                                    className="hs-dropdown-menu z-50 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[10rem] bg-white shadow-md rounded-lg p-2 mt-2 "
                                    aria-labelledby="hs-dropdown-status-trigger"
                                  >
                                    <button className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 ">
                                      Activate
                                    </button>
                                    <button className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 ">
                                      Deactivate
                                    </button>
                                    <button className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 ">
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-gray-100 text-black ">
                                <FontAwesomeIcon
                                  icon={faClockRotateLeft}
                                  className="w-2.5 h-2.5"
                                />
                                {moment(user?.updatedAt, 'YYYYMMDD').fromNow()}
                              </span>
                            </div>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {moment(user?.createdAt).format(
                                  'MMM Do YYYY, h:mm:ss a'
                                )}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="6" className="px-6 py-3 text-center">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-16 h-16 text-gray-300"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 2a8 8 0 100 16 8 8 0 000-16zM7 9a1 1 0 112 0v3a1 1 0 11-2 0V9zm6 0a1 1 0 112 0v3a1 1 0 11-2 0V9z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p className="text-gray-500">No Users Found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* End Table */}
              {/* Footer */}
              <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                <div>
                  <p className="text-sm text-gray-600 ">
                    <span className="font-semibold text-gray-800 ">
                      {users?.filter(searched(keyword))?.length}
                    </span>{' '}
                    results
                  </p>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="w-3 h-3"
                      />
                      Prev
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      Next
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="w-3 h-3"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        </div>
      </div>
      {/* End Card */}
    </div>
  );
}

export default Users;
