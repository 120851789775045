import axios from 'axios';

export const getCoupons = async () =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/coupons`);

export const removeCoupon = async (couponId, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/coupon/${couponId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });
export const updateCoupon = async (couponId, status, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_BASE_URL}/coupon/${couponId}`,
    { status },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

export const createCoupon = async (coupon, authtoken) =>
  await axios.post(
    `${process.env.REACT_APP_BASE_URL}/coupon`,
    { coupon },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
