// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebaseConfig = {
  apiKey: 'AIzaSyAhH8zgKKFsT-mnouU1uT_c9o4IdRvhKTM',
  authDomain: 'your-adda.firebaseapp.com',
  projectId: 'your-adda',
  storageBucket: 'your-adda.appspot.com',
  messagingSenderId: '235107390573',
  appId: '1:235107390573:web:8cff036680071e8b6e9b64',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// export
export const auth = getAuth(app);
