import axios from 'axios';

export const getSubs = async () =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/subs`);

export const getSub = async (slug) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/sub/${slug}`);

export const removeSub = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/sub/${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const updateSub = async (slug, sub, authtoken) =>
  await axios.put(`${process.env.REACT_APP_BASE_URL}/sub/${slug}`, sub, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const createSub = async (sub, authtoken) =>
  await axios.post(`${process.env.REACT_APP_BASE_URL}/sub`, sub, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });
