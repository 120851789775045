import React, { useState, useCallback, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { updatePotSize } from 'redux/functions/potSize';
import { useAuthStore } from 'store/authStore';

export default function UpdateCategory({ potSize, slug, fetchData }) {
  const { user } = useAuthStore();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    potSize: potSize,
  });

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        await updatePotSize(slug, formData, user.token);
        setFormData({
          potSize: '',
        });
        setLoading(false);
        setOpen(false);
        fetchData();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [formData, slug, user.token]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        onClick={handleClickOpen}
        className="flex items-center w-full px-3 py-2 text-sm text-gray-800 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
      >
        <FontAwesomeIcon icon={faPen} className="w-4 h-4" />
        Edit
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Pot Size</DialogTitle>
        <DialogContent>
          <input
            id="af-potSize-name"
            type="text"
            name="potSize"
            autoFocus
            value={formData.potSize}
            onChange={handleChange}
            className="relative block w-full px-3 py-2 text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
            placeholder="Enter Pot Size name"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>
            {loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="w-4 h-4 mr-2 -ml-1 animate-spin"
              />
            ) : (
              'Update Pot Size'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
