import React, { useState, useCallback, useEffect } from 'react';
import { getBlogsByCount } from 'redux/functions/blog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faPlusCircle,
  faMagnifyingGlass,
  faEllipsis,
  faTrash,
  faEye,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useAuthStore } from 'store/authStore';

const Blogs = () => {
  const { user, removeUser, setUser } = useAuthStore();
  const [keyword, setKeyword] = useState('');
  const [blogs, setBlogs] = useState([]);

  const loadAllProducts = useCallback(async () => {
    getBlogsByCount(10)
      .then((res) => {
        setBlogs(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };

  const searched = (keyword) => (c) => c.title.toLowerCase().includes(keyword);

  useEffect(() => {
    loadAllProducts();
  }, [loadAllProducts]);

  return (
    <div className="max-w-[85rem]  py-10  lg:py-14 mx-auto">
      {/* Card */}
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
              {/* Header */}
              <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                <div className="sm:col-span-1">
                  <label
                    htmlFor="hs-as-table-product-review-search"
                    className="sr-only"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="hs-as-table-product-review-search"
                      name="hs-as-table-product-review-search"
                      className="block w-full px-3 py-2 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="w-4 h-4 text-gray-400"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 "
                    >
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="w-4 h-4"
                      />
                      Add Blog
                    </button>
                  </div>
                </div>
              </div>
              {/* End Header */}
              {/* Table */}
              {blogs?.length > 0 ? (
                <div className="grid grid-cols-1 gap-2 px-5 py-2 md:grid-cols-3 xl:grid-cols-3">
                  {blogs?.filter(searched(keyword))?.map((blog) => (
                    <div
                      key={blog?._id}
                      className="flex flex-col w-full bg-white border shadow-sm rounded-xl "
                    >
                      <div className="relative">
                        <img
                          className="w-full h-auto rounded-t-xl"
                          src={blog?.images[0]?.url}
                          alt={blog?.title}
                        />
                        <div className="absolute top-0 right-0 inline-flex items-center justify-center w-8 h-8 ">
                          <div className="relative inline-flex hs-dropdown">
                            <button
                              id="hs-dropdown-custom-icon-trigger"
                              type="button"
                              className="inline-flex items-center justify-center gap-2 p-3 text-sm font-medium text-gray-700 align-middle transition-all hs-dropdown-toggle "
                            >
                              <FontAwesomeIcon
                                icon={faEllipsis}
                                className="w-4 h-4 p-1 text-white bg-indigo-400 rounded-md"
                              />
                            </button>
                            <div
                              className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden  divide-y divide-gray-200 min-w-[10rem] z-20 bg-white shadow-2xl rounded-lg p-2 mt-2  "
                              aria-labelledby="hs-table-dropdown-1"
                            >
                              <div className="py-2 first:pt-0 last:pb-0">
                                <span className="block px-3 py-2 text-xs font-medium text-gray-400 uppercase ">
                                  Actions
                                </span>
                                <button className="flex items-center w-full px-3 py-2 text-sm text-gray-800 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 ">
                                  <FontAwesomeIcon
                                    icon={faPen}
                                    className="w-4 h-4"
                                  />
                                  Edit
                                </button>
                                <button className="flex items-center w-full px-3 py-2 text-sm text-gray-800 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500">
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="w-4 h-4"
                                  />
                                  Preview
                                </button>
                              </div>
                              <div className="py-2 first:pt-0 last:pb-0">
                                <button
                                  type="button"
                                  className="flex items-center w-full px-3 py-2 text-sm text-red-600 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="w-4 h-4"
                                  />
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-4 md:p-5">
                        <h3 className="text-lg font-bold leading-snug text-gray-800 ">
                          {blog?.title}
                        </h3>
                        <p className="mt-1 text-sm text-gray-800">
                          {blog?.metaDescription?.length > 100 ? (
                            <>{blog?.metaDescription?.substring(0, 100)}...</>
                          ) : (
                            <>{blog?.metaDescription}</>
                          )}
                        </p>
                        <p className="mt-5 text-xs text-gray-500 ">
                          Last updated{' '}
                          {moment(blog?.updatedAt, 'YYYYMMDD').fromNow()}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-center py-10">
                  <div className="flex flex-col items-center justify-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-16 h-16 text-gray-300"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 2a8 8 0 100 16 8 8 0 000-16zM7 9a1 1 0 112 0v3a1 1 0 11-2 0V9zm6 0a1 1 0 112 0v3a1 1 0 11-2 0V9z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p className="text-gray-500">No Blogs found</p>
                  </div>
                </div>
              )}

              {/* End Table */}
              {/* Footer */}
              <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                {blogs?.length > 0 && (
                  <>
                    {' '}
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">
                          {blogs?.filter(searched(keyword))?.length}
                        </span>{' '}
                        results
                      </p>
                    </div>
                    <div>
                      <div className="inline-flex gap-x-2">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                        >
                          <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="w-3 h-3"
                          />
                          Prev
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                        >
                          Next
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="w-3 h-3"
                          />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* End Footer */}
            </div>
          </div>
        </div>
      </div>
      {/* End Card */}
    </div>
  );
};

export default Blogs;
