import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getProductsByCount, removeProduct } from 'redux/functions/product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faSort,
  faChevronRight,
  faChevronLeft,
  faEllipsis,
  faTrash,
  faPen,
  faEye,
  faStar,
  faPlusCircle,
  faArrowTrendUp,
  faCircleQuestion,
  faCubes,
  faSortUp,
  faSortDown,
  faMagnifyingGlass,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { useAuthStore } from 'store/authStore';

export default function Gifting() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [products, setProducts] = useState([]);
  const [sortOrderState, setSortOrderState] = useState('original');
  console.log(products);

  const loadAllProducts = useCallback(async () => {
    setLoading(true);
    getProductsByCount()
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };

  const searched = (keyword) => (c) => c.title.toLowerCase().includes(keyword);

  const handleSortProductsAlphabetically = () => {
    const sortedProducts = [...products];

    let sortOrder;
    if (sortOrderState === 'asc') {
      sortedProducts.sort((a, b) => a.title.localeCompare(b.title));
      sortOrder = 'desc';
    } else if (sortOrderState === 'desc') {
      sortedProducts.sort((a, b) => b.title.localeCompare(a.title));
      sortOrder = 'original';
    } else {
      loadAllProducts();
      sortOrder = 'asc';
    }

    setSortOrderState(sortOrder);
    setProducts(sortedProducts);
  };

  const handleSortBySales = () => {
    const sortedProducts = [...products];

    let sortOrder;
    if (sortOrderState === 'asc') {
      sortedProducts.sort((a, b) => a.sold - b.sold);
      sortOrder = 'desc';
    } else if (sortOrderState === 'desc') {
      sortedProducts.sort((a, b) => b.sold - a.sold);
      sortOrder = 'original';
    } else {
      loadAllProducts();
      sortOrder = 'asc';
    }

    setSortOrderState(sortOrder);
    setProducts(sortedProducts);
  };

  const handleRemove = useCallback(
    async (slug) => {
      if (window.confirm('Are you sure you want to delete this product?')) {
        try {
          await removeProduct(slug, user.token);
          loadAllProducts();
        } catch (err) {
          if (err.response.status === 400) {
            console.log(err.response.data?.errMsg);
          } else {
            console.error(err);
          }
        }
      }
    },
    [user?.token]
  );
  useEffect(() => {
    loadAllProducts();
  }, [loadAllProducts]);

  return (
    <div className="max-w-[85rem]  py-10  lg:py-14 mx-auto">
      {/* Card */}
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
              {/* Header */}
              <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                <div className="flex items-center gap-x-3">
                  <label
                    htmlFor="hs-as-table-product-review-search"
                    className="sr-only"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="hs-as-table-product-review-search"
                      name="hs-as-table-product-review-search"
                      className="block w-full px-3 py-2 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="w-4 h-4 text-gray-400"
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => navigate('/panel/products/add-product')}
                    className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 "
                  >
                    <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4" />
                    Add Product
                  </button>
                </div>
              </div>
              {/* End Header */}
              {/* Table */}
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Image
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div
                        onClick={handleSortProductsAlphabetically}
                        className="inline-flex items-center cursor-pointer group gap-x-2"
                      >
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Name
                        </span>
                        <button className="flex items-center justify-center w-5 h-5 text-gray-400 border border-gray-200 rounded group-hover:bg-gray-200 ">
                          {sortOrderState === 'asc' ? (
                            <FontAwesomeIcon
                              icon={faSortDown}
                              className="w-2.5 h-2.5"
                            />
                          ) : sortOrderState === 'desc' ? (
                            <FontAwesomeIcon
                              icon={faSortUp}
                              className="w-2.5 h-2.5"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSort}
                              className="w-2.5 h-2.5"
                            />
                          )}
                        </button>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Description
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-3 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          SKU / Color / Size / Quantity
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Category
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div
                        onClick={handleSortBySales}
                        className="inline-flex items-center cursor-pointer group gap-x-2"
                      >
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Sales
                        </span>
                        <button className="flex items-center justify-center w-5 h-5 text-gray-400 border border-gray-200 rounded group-hover:bg-gray-200 ">
                          {sortOrderState === 'asc' ? (
                            <FontAwesomeIcon
                              icon={faSortUp}
                              className="w-2.5 h-2.5"
                            />
                          ) : sortOrderState === 'desc' ? (
                            <FontAwesomeIcon
                              icon={faSortDown}
                              className="w-2.5 h-2.5"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSort}
                              className="w-2.5 h-2.5"
                            />
                          )}
                        </button>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Actions
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody className="divide-y divide-gray-200 ">
                    <tr>
                      <td className="px-6 py-4 text-center" colSpan="7">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="w-10 h-10 text-gray-300 animate-spin"
                          />
                          <p className="text-gray-500">Getting Products...</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="divide-y divide-gray-200 ">
                    {products?.length > 0 ? (
                      <>
                        {products?.filter(searched(keyword)).map((p) => (
                          <tr
                            key={p?._id}
                            className="bg-white hover:bg-gray-50 "
                          >
                            <td className=" h-px min-w-[8rem] px-3 py-2 w-32">
                              <div className="z-10 block ">
                                <img
                                  className="object-cover w-20 h-20 rounded-xl"
                                  src={p?.images?.cover}
                                  alt={p?.title}
                                />
                              </div>
                            </td>
                            <td className="h-px w-64 min-w-[12rem]">
                              <div className="relative z-10 block">
                                <div className="px-6 py-2 hover:underline">
                                  <a
                                    href="https://your-adda.vercel.app/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="block text-base font-bold text-gray-600 cursor-pointer decoration-2 "
                                  >
                                    {p?.title}
                                  </a>
                                </div>
                                <div className="px-6 pb-2 ">
                                  <div className="block text-sm text-green-600 decoration-2 ">
                                    {p?.price.toLocaleString('en-IN', {
                                      style: 'currency',
                                      currency: 'INR',
                                    })}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="h-px w-72 min-w-[18rem]">
                              <div className="relative z-10 block">
                                <div className="px-6 py-2">
                                  <p
                                    className="text-sm text-gray-500 break-words"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        p?.description?.length > 100
                                          ? p?.description?.substring(0, 100) +
                                            '...'
                                          : p?.description,
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="h-px w-96 min-w-[19rem]">
                              <div className="relative z-10 block">
                                <div className="flex flex-col px-6 py-2">
                                  {p?.varients?.length > 0 &&
                                  p?.varients[0]?.size ? (
                                    p?.varients?.map((v) => (
                                      <div
                                        key={v?._id}
                                        className="flex items-center gap-x-2"
                                      >
                                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                                          {v?.sku ? v?.sku : 'N/A'}
                                        </span>
                                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                                          {v?.color ? v?.color : 'N/A'}
                                        </span>
                                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                                          {v?.size ? v?.size : 'N/A'}
                                        </span>
                                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                                          {v?.quantity ? v?.quantity : 'N/A'}
                                        </span>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="flex items-center gap-x-2">
                                      <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                                        {p?.sku ? p?.sku : 'N/A'}
                                      </span>
                                      <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                                        N/A
                                      </span>
                                      <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                                        N/A
                                      </span>
                                      <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                                        N/A
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="relative z-10 block">
                                <div className="px-6 py-2">
                                  <span className="inline-flex items-center gap-1.5 py-1 px-2 rounded-md text-xs font-medium bg-gray-100 text-gray-800  ">
                                    {p?.category?.name}
                                  </span>
                                </div>
                                <div className="flex items-center px-6 py-2 gap-x-1">
                                  <span className="px-1 py-0 text-xs font-medium text-gray-800 bg-gray-100 rounded-md ">
                                    {p?.ratings?.length > 0 && (
                                      <FontAwesomeIcon
                                        icon={faStar}
                                        className="w-3 h-3 pr-1 text-yellow-400"
                                      />
                                    )}
                                    {p?.ratings?.length > 0
                                      ? p?.ratings?.reduce(
                                          (acc, item) => acc + item.star,
                                          0
                                        ) /
                                          p?.ratings?.length +
                                        ' / 5'
                                      : 'Not Rated Yet'}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="w-px h-px whitespace-nowrap">
                              <div className="relative z-10 block">
                                <div className="flex items-center px-6 py-2 ">
                                  <div className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-md text-xs font-medium bg-green-300 text-gray-800  ">
                                    <FontAwesomeIcon
                                      icon={faArrowTrendUp}
                                      className="w-3 h-3 text-gray-800"
                                    />
                                    {p?.sold}
                                  </div>
                                  <div className="hs-tooltip [--trigger:click] inline-flex items-center gap-1.5 py-0.5 px-2">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion}
                                      className="w-3 h-3 text-gray-800 hs-tooltip-toggle"
                                    />
                                    <span
                                      className="absolute z-10 invisible block px-2 py-1 text-xs font-medium text-white transition-opacity bg-gray-900 rounded-md shadow-sm opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible "
                                      role="tooltip"
                                    >
                                      Product Sold Count
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center px-6 py-2 ">
                                  <div className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-md text-xs font-medium bg-indigo-300 text-gray-800  ">
                                    <FontAwesomeIcon
                                      icon={faCubes}
                                      className="w-3 h-3 text-gray-800"
                                    />
                                    {/* // map throung the varients and add the quantity */}
                                    {p?.varients?.reduce(
                                      (acc, item) => acc + item.quantity,
                                      0
                                    )}
                                  </div>
                                  <div className="hs-tooltip [--trigger:click] inline-flex items-center gap-1.5 py-0.5 px-2">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion}
                                      className="w-3 h-3 text-gray-800 hs-tooltip-toggle"
                                    />
                                    <span
                                      className="absolute z-10 invisible block px-2 py-1 text-xs font-medium text-white transition-opacity bg-gray-900 rounded-md shadow-sm opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible "
                                      role="tooltip"
                                    >
                                      Product Quantity Left
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="w-px h-px whitespace-nowrap">
                              <div className="px-6 py-2">
                                <div className="hs-dropdown relative inline-block [--placement:bottom-right]">
                                  <button
                                    id="hs-table-dropdown-1"
                                    type="button"
                                    className="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm   "
                                  >
                                    <FontAwesomeIcon
                                      icon={faEllipsis}
                                      className="w-4 h-4"
                                    />
                                  </button>
                                  <div
                                    className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden  divide-y divide-gray-200 min-w-[10rem] z-20 bg-white shadow-2xl rounded-lg p-2 mt-2  "
                                    aria-labelledby="hs-table-dropdown-1"
                                  >
                                    <div className="py-2 first:pt-0 last:pb-0">
                                      <span className="block px-3 py-2 text-xs font-medium text-gray-400 uppercase ">
                                        Actions
                                      </span>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `/panel/products/edit-product/${p?._id}`
                                          )
                                        }
                                        className="flex items-center w-full px-3 py-2 text-sm text-gray-800 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                                      >
                                        <FontAwesomeIcon
                                          icon={faPen}
                                          className="w-4 h-4"
                                        />
                                        Edit
                                      </button>
                                      <button className="flex items-center w-full px-3 py-2 text-sm text-gray-800 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500">
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="w-4 h-4"
                                        />
                                        Preview
                                      </button>
                                    </div>
                                    <div className="py-2 first:pt-0 last:pb-0">
                                      <button
                                        type="button"
                                        onClick={() => handleRemove(p?.slug)}
                                        className="flex items-center w-full px-3 py-2 text-sm text-red-600 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="w-4 h-4"
                                        />
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td className="px-6 py-4 text-center" colSpan="7">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-16 h-16 text-gray-300"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 2a8 8 0 100 16 8 8 0 000-16zM7 9a1 1 0 112 0v3a1 1 0 11-2 0V9zm6 0a1 1 0 112 0v3a1 1 0 11-2 0V9z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="text-gray-500">No Products Found</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
              {/* End Table */}
              {/* Footer */}
              <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                {products?.length > 0 && (
                  <>
                    {' '}
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">
                          {products?.filter(searched(keyword))?.length}
                        </span>{' '}
                        results
                      </p>
                    </div>
                    <div>
                      <div className="inline-flex gap-x-2">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                        >
                          <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="w-3 h-3"
                          />
                          Prev
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                        >
                          Next
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="w-3 h-3"
                          />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* End Footer */}
            </div>
          </div>
        </div>
      </div>
      {/* End Card */}
    </div>
  );
}
