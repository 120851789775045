import {
  faUpload,
  faTrash,
  faArrowRightArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadWidget from './UploadWidget';
export default function Uploader({ images, setImages, error, handleOnUpload }) {
  // console.log('images', images);
  return (
    <form action="#" className="w-full">
      <div className="flex flex-col items-start justify-between md:flex-row gap-x-2 ">
        {images.cover && (
          <div className="relative mb-5 group">
            <img
              className="object-cover transition-all duration-300 ease-in-out rounded-md cursor-pointer group-hover:opacity-50 w-[24rem] h-[25rem]"
              src={images.cover}
              alt="cover "
            />
            <div className="absolute flex items-center gap-3 transition-all duration-300 ease-in-out transform -translate-x-1/2 -translate-y-1/2 opacity-0 left-1/2 top-1/2 group-hover:opacity-100">
              <button
                className="flex items-center justify-center w-8 h-8 text-white rounded-full outline-none"
                onClick={() => {
                  setImages((prevState) => ({
                    ...prevState,
                    cover: '',
                  }));
                }}
              >
                <FontAwesomeIcon
                  className="text-xl text-black "
                  icon={faTrash}
                />
              </button>
              <div className="flex items-center justify-center w-8 h-8 text-white rounded-full outline-none ">
                {' '}
                <UploadWidget onUpload={handleOnUpload}>
                  {({ open }) => {
                    const handleOnClick = async (e, type) => {
                      e.preventDefault();
                      if (type === 'cover') {
                        console.log('cover');
                        window.localStorage.setItem('uploadType', 'cover');
                      }

                      open();
                    };

                    return (
                      <>
                        <button
                          onClick={(e) => {
                            handleOnClick(e, 'cover');
                          }}
                          className="w-full"
                        >
                          <FontAwesomeIcon
                            className="text-xl text-black "
                            icon={faArrowRightArrowLeft}
                          />
                        </button>
                      </>
                    );
                  }}
                </UploadWidget>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 py-2 md:grid-flow-col md:py-0 gap-y-3 gap-x-2 md:grid-rows-2">
          {images.more.length > 0 &&
            images.more.map((img, id) => (
              <div
                key={id}
                className="relative flex flex-col items-center justify-center mb-5 group"
              >
                <img
                  className="object-cover w-48 h-48 transition-all duration-300 ease-in-out rounded-md cursor-pointer group-hover:opacity-50"
                  src={img}
                  alt="more "
                />
                <div className="absolute flex items-center gap-3 transition-all duration-300 ease-in-out transform -translate-x-1/2 -translate-y-1/2 opacity-0 left-1/2 top-1/2 group-hover:opacity-100">
                  <button
                    className="flex items-center justify-center w-8 h-8 rounded-full outline-none"
                    onClick={() => {
                      setImages((prevState) => ({
                        ...prevState,
                        more: images.more.filter((image) => image !== img),
                      }));
                    }}
                  >
                    <FontAwesomeIcon
                      className="text-xl text-meta-1 "
                      icon={faTrash}
                    />
                  </button>
                  <div className="flex items-center justify-center w-8 h-8 rounded-full outline-none ">
                    {' '}
                    <UploadWidget onUpload={handleOnUpload}>
                      {({ open }) => {
                        const handleOnClick = async (e, type) => {
                          e.preventDefault();
                          if (type === 'replace') {
                            console.log('replace');
                            window.localStorage.setItem(
                              'uploadType',
                              'replace'
                            );
                            window.localStorage.setItem(
                              'replaceIndex',
                              images.more.indexOf(img)
                            );
                          }

                          open();
                        };

                        return (
                          <>
                            <button
                              onClick={(e) => {
                                handleOnClick(e, 'replace');
                              }}
                              className="w-full"
                            >
                              <FontAwesomeIcon
                                className="text-xl text-meta-3 "
                                icon={faArrowRightArrowLeft}
                              />
                            </button>
                          </>
                        );
                      }}
                    </UploadWidget>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <UploadWidget onUpload={handleOnUpload}>
        {({ open }) => {
          const handleOnClick = async (e, type) => {
            e.preventDefault();
            if (type === 'cover') {
              console.log('cover');
              window.localStorage.setItem('uploadType', 'cover');
            } else {
              console.log('more');
              window.localStorage.setItem('uploadType', 'more');
            }

            open();
          };

          return (
            <>
              {!images.cover && (
                <button
                  onClick={(e) => {
                    handleOnClick(e, 'cover');
                  }}
                  className="w-full"
                >
                  <div className="relative  block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray px-4 py-4 text-sm text-gray-500 sm:py-7.5">
                    <div className="flex flex-col items-center justify-center space-y-3">
                      <span className="flex items-center justify-center w-10 h-10 bg-white border rounded-full border-stroke ">
                        <FontAwesomeIcon icon={faUpload} />
                      </span>
                      <p>
                        <span className="text-primary">
                          Click to upload cover image
                        </span>{' '}
                      </p>
                      <p className="mt-1.5">SVG, PNG, JPEG, JPG or GIF</p>
                    </div>
                  </div>
                </button>
              )}
              {images.cover && (
                <button
                  onClick={(e) => {
                    handleOnClick(e, 'more');
                  }}
                  className="w-full"
                >
                  <div className="relative  block w-full cursor-pointer appearance-none rounded border-2 border-dashed text-sm text-gray-500 border-primary bg-gray px-4 py-4  sm:py-7.5">
                    <div className="flex flex-col items-center justify-center space-y-3">
                      <span className="flex items-center justify-center w-10 h-10 bg-white border rounded-full border-stroke ">
                        <FontAwesomeIcon icon={faUpload} />
                      </span>
                      <p>
                        <span className="text-primary">
                          Click to upload more images
                        </span>{' '}
                      </p>
                      <p className="mt-1.5">SVG, PNG, JPEG, JPG or GIF</p>
                    </div>
                  </div>
                </button>
              )}
            </>
          );
        }}
      </UploadWidget>
      {error && <div className="mt-2 text-sm text-red-500">{error}</div>}
    </form>
  );
}
