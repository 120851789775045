import {
  useState,
  useRef,
  useEffect,
  Suspense,
  lazy,
  useCallback,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { createProduct, updateProduct } from 'redux/functions/product';
import toast from 'react-hot-toast';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';
import { Editor } from '@tinymce/tinymce-react';

import { getCategories } from 'redux/functions/category';
import { getSubs } from 'redux/functions/sub';
import {
  faIndianRupeeSign,
  faInfo,
  faInfoCircle,
  faPlusCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileUpload from 'utils/FileUpload';
import Uploader from 'components/Uploadler';
import ProductVarients from './ProductVarients';
import Tooltip from '@mui/material/Tooltip';
import { useAuthStore } from 'store/authStore';

//Error messages

const ErrorMessages = [
  {
    errCode: 11000,
    errMsg: 'Duplicate entry found',
  },
  {
    errCode: 'auth/id-token-expired',
    errMsg: 'Your session has expired. Please login again',
  },
];

// Debounce function
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const ProductForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  console.log('ID', id);
  const editorRef = useRef(null);
  const [slug, setSlug] = useState(null);
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [error, updateError] = useState();
  const [formData, setFormData] = useState({});
  const [categories, setCategories] = useState([]);
  const [subs, setSubs] = useState([]);
  const [images, setImages] = useState(() => {
    return {
      cover: null,
      more: [],
    };
  });

  const [varients, setVarients] = useState([]);

  const [values, setValues] = useState(() => {
    // Try to load the saved values from localStorage
    const savedValues = localStorage.getItem('values');

    if (savedValues) {
      // If saved values exist, parse them from the JSON string and return them
      return JSON.parse(savedValues);
    } else {
      // If no saved values exist, return the initial state
      return {
        title: '',
        price: '',
        discountedPrice: '',
        metaDescription: '',
        description: '',
        category: '',
        subs: [],
      };
    }
  });

  const debouncedValues = useDebounce(values, 200);

  const fetchCategory = useCallback(async () => {
    getCategories(user?.token)
      .then((res) => {
        setCategories(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response.data?.errMsg);
      });
  }, [user?.token]);

  const fetchSubs = useCallback(async () => {
    getSubs(user?.token)
      .then((res) => {
        setSubs(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response.data?.errMsg);
      });
  }, [user?.token]);

  const handleChange = useCallback((event) => {
    // if (event.target.name === 'subs') {
    // setValues((prevState) => ({
    //   ...prevState,
    //   [event.target.name]: event.target.value.replace(/[^0-9]/g, ''),
    // }));
    // setValues((prevState) => ({
    //   ...prevState,
    //   [event.target.name]: event.target.value,
    // }));
    // } else {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    // }
  }, []);

  const handleOnUpload = (error, result) => {
    if (error) {
      updateError(error);
      // eslint-disable-next-line no-undef
      widget.close({
        quiet: true,
      });
      return;
    }

    // console.log('UPLOADTYPE', window.localStorage.getItem('uploadType'));

    if (window.localStorage.getItem('uploadType') === 'cover') {
      setImages((prevState) => ({
        ...prevState,
        cover: result?.info?.secure_url,
      }));
      window.localStorage.setItem('uploadType', null);
    } else if (window.localStorage.getItem('uploadType') === 'more') {
      setImages((prevState) => ({
        ...prevState,
        more: [...prevState.more, result?.info?.secure_url],
      }));
      window.localStorage.setItem('uploadType', null);
    } else if (window.localStorage.getItem('uploadType') === 'replace') {
      const replaceIndex = window.localStorage.getItem('replaceIndex');

      // console.log('REPLACEINDEX', replaceIndex);

      setImages((prevState) => {
        const newImages = [...prevState.more];
        newImages[replaceIndex] = result?.info?.secure_url;
        return {
          ...prevState,
          more: newImages,
        };
      });

      window.localStorage.setItem('replaceIndex', null);
      window.localStorage.setItem('uploadType', null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      ...formData,
      // price: values.price.replace(/,/g, ''),
      price: Number(values.price),
      discountedPrice: Number(values.discountedPrice),
      images: {
        cover: images.cover,
        more: images.more,
      },
      varients: varients,
    };
    let res;
    if (id !== undefined) {
      res = await updateProduct(slug, data, user?.token);
    } else {
      res = await createProduct(data, user?.token);
    }
    if (res?.status === 200) {
      if (id !== undefined) {
        toast.success('Product updated successfully');
      } else {
        toast.success('Product created successfully');
      }
      navigate('/panel/products');
    } else {
      toast.error('Something went wrong');
    }
    return;
  };

  // if id exists, fetch product details and set values
  useEffect(() => {
    if (id !== undefined) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/product-details`,
          { id: id },
          {
            headers: {
              authtoken: user?.token,
            },
          }
        )
        .then((res) => {
          const product = res.data;
          setSlug(product.slug);
          setValues((prevState) => ({
            ...prevState,
            title: product.title,
            price: Number(product.price),
            discountedPrice: product.discountedPrice,
            metaDescription: product.metaDescription,
            description: product.description,
            category: product.category._id,
            subs: product.subs.map((sub) => sub._id),
          }));
          setImages((prevState) => ({
            ...prevState,
            cover: product.images.cover,
            more: product?.images?.more?.length > 0 ? product.images.more : [],
          }));
          console.log(product.images.cover);
          setVarients(product.varients);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      ...debouncedValues,
    }));

    // console.log('formData', JSON.stringify(formData, null, 2));
  }, [debouncedValues, images]);

  // useEffect(() => {
  //   localStorage.setItem('values', JSON.stringify(debouncedValues));
  // }, [debouncedValues]);

  useEffect(() => {
    fetchCategory();
    fetchSubs();
  }, [fetchCategory, fetchSubs]);

  return (
    <>
      <div className="max-w-6xl py-10 mx-auto lg:py-14">
        {/* Card */}
        <div className="p-4 shadow bg-gray-50 rounded-xl sm:p-7 ">
          <div className="mb-8">
            <h2 className="text-xl font-bold text-gray-800 ">
              {id ? 'Edit Product' : 'Add New Product'}
            </h2>
            <p className="text-sm text-gray-600 ">
              {id ? 'Edit your product details' : 'Add your product details'}
            </p>
          </div>
          {/* Grid */}
          <div className="grid gap-8 sm:grid-cols-12 sm:gap-8">
            <div className="sm:col-span-2">
              <Tooltip title="Upload a cover image for your product &amp; add more images to showcase your product">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="w-3.5 h-3.5 text-gray-500 inline-flex mx-1"
                />
              </Tooltip>
              <label className="inline-block text-sm font-bold text-gray-800 ">
                Product Images
              </label>
            </div>

            <div className="sm:col-span-10 ">
              <Uploader
                images={images}
                setImages={setImages}
                error={error}
                handleOnUpload={handleOnUpload}
              />
            </div>
            {/* Title or Name */}
            <div className="sm:col-span-12">
              <div className="grid gap-8 p-5 bg-gray-100 sm:grid-cols-12 sm:gap-10 rounded-xl ">
                <div className="sm:col-span-2">
                  <Tooltip title="Enter the name of your product. This will be displayed on your store">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="w-3.5 h-3.5 text-gray-500 inline-flex mx-1"
                    />
                  </Tooltip>
                  <label
                    htmlFor="af-product-name"
                    className="inline-block text-sm font-bold text-gray-800 "
                  >
                    Product Name
                  </label>
                </div>

                <div className="sm:col-span-10">
                  <div className="sm:flex">
                    <input
                      id="af-product-name"
                      type="text"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm pr-11 sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Enter product name"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Price */}
            <div className="sm:col-span-12">
              <div className="grid gap-8 p-5 bg-gray-100 sm:grid-cols-12 sm:gap-10 rounded-xl ">
                <div className="sm:col-span-2">
                  <Tooltip title="Enter the selling price of your product. You can also add discounted price if you want">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="w-3.5 h-3.5 text-gray-500 inline-flex mx-1"
                    />
                  </Tooltip>
                  <label
                    htmlFor="af-product-price"
                    className="inline-block text-sm font-bold text-gray-800 "
                  >
                    Product Price
                  </label>
                </div>
                <div className="sm:col-span-10">
                  <div className="gap-2 sm:flex">
                    <div className="relative w-full">
                      <input
                        id="af-product-price"
                        type="number"
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                        className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                        placeholder="Enter product price"
                        // onInput={(e) => {
                        //   // Remove non-numeric characters and format to Indian currency
                        //   const inputValue = e.target.value.replace(
                        //     /[^0-9]/g,
                        //     ''
                        //   );
                        //   const formattedValue = inputValue.replace(
                        //     /\B(?=(\d{3})+(?!\d))/g,
                        //     ','
                        //   );
                        //   e.target.value = formattedValue;
                        // }}
                      />

                      <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faIndianRupeeSign}
                          className="w-4 h-4 text-gray-400"
                        />
                      </div>
                    </div>
                    <div className="relative w-full">
                      <input
                        id="af-product-discounted-price"
                        type="number"
                        name="discountedPrice"
                        value={values.discountedPrice}
                        onChange={handleChange}
                        className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                        placeholder="Enter discounted price (optional)"
                      />

                      <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faIndianRupeeSign}
                          className="w-4 h-4 text-gray-400"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Collection and Tags */}
            <div className="z-10 sm:col-span-12">
              <div className="grid gap-8 p-5 bg-gray-100 sm:grid-cols-12 sm:gap-10 rounded-xl ">
                <div className="sm:col-span-2">
                  <Tooltip title="Select the collection and tags for your product. This will help your customers to find your product easily. The collection will be displayed on your store. Tags will be used for SEO purposes">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="w-3.5 h-3.5 text-gray-500 inline-flex mx-1"
                    />
                  </Tooltip>
                  <label
                    htmlFor="af-product-category"
                    className="inline-block text-sm font-bold text-gray-800 "
                  >
                    Collection &amp;
                  </label>
                  <label
                    htmlFor="af-product-subcategory"
                    className="inline-block text-sm font-bold text-gray-800 "
                  >
                    {' '}
                    Tags
                  </label>
                </div>

                <div className="sm:col-span-10">
                  <div className="gap-x-10 sm:flex">
                    <CreatableSelect
                      id="af-product-category"
                      className="relative block w-full -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Select Collection"
                      isClearable
                      isSearchable
                      name="category"
                      styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: '#fff',
                          width: '100%',
                          '&:hover': {
                            borderColor: '#fff',
                          },
                        }),
                      }}
                      onChange={(e) => {
                        setValues((prevState) => ({
                          ...prevState,
                          category: e?.value,
                        }));
                      }}
                      options={
                        categories?.length > 0 &&
                        categories?.map((c) => ({
                          value: c?._id,
                          label: c?.name,
                        }))
                      }
                      value={
                        values?.category
                          ? {
                              value: values?.category,
                              label: categories?.find(
                                (c) => c?._id === values?.category
                              )?.name,
                            }
                          : null
                      }
                    />

                    <CreatableSelect
                      className="relative z-10 block w-full -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      id="af-product-subcategory"
                      placeholder="Select Tags"
                      isMulti
                      name="subs"
                      styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: '#fff',
                          width: '100%',
                          '&:hover': {
                            borderColor: '#fff',
                          },
                        }),
                      }}
                      onChange={(e) => {
                        setValues((prevState) => ({
                          ...prevState,
                          subs: e?.map((sub) => sub?.value),
                        }));
                      }}
                      options={
                        values?.category === ''
                          ? []
                          : subs
                              ?.filter(
                                (sub) => sub?.parent?._id === values?.category
                              )
                              ?.map((sub) => ({
                                value: sub?._id,
                                label: sub?.name,
                              }))
                      }
                      value={
                        values?.subs?.length > 0
                          ? subs
                              ?.filter((sub) =>
                                values?.subs?.includes(sub?._id)
                              )
                              ?.map((sub) => {
                                return {
                                  value: sub?._id,
                                  label: sub?.name,
                                };
                              })
                          : []
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Varients */}
            <div className="sm:col-span-12">
              <div className="grid gap-8 p-5 bg-gray-100 sm:grid-cols-12 sm:gap-10 rounded-xl ">
                <div className=" sm:col-span-2">
                  <Tooltip title="Create all possible combinations of size and color to create varients of your product. Varients can be used to sell different sizes and colors of your product.">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="w-3.5 h-3.5 text-gray-500 inline-flex mx-1"
                    />
                  </Tooltip>
                  <label
                    htmlFor="af-account-bio"
                    className="inline-block text-sm font-bold text-gray-800 "
                  >
                    Varients
                  </label>
                </div>
                <div className="sm:col-span-10">
                  <ProductVarients
                    setVarients={setVarients}
                    varients={varients}
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-12">
              <div className="grid gap-8 p-5 bg-gray-100 sm:grid-cols-12 sm:gap-10 rounded-xl ">
                <div className="sm:col-span-2">
                  <Tooltip title="Create all possible combinations of size and color to create varients">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="w-3.5 h-3.5 text-gray-500 inline-flex mx-1"
                    />
                  </Tooltip>
                  <label
                    htmlFor="af-account-bio"
                    className="inline-block text-sm font-bold text-gray-800 "
                  >
                    Description
                  </label>
                </div>

                <div className="sm:col-span-10">
                  <Editor
                    disabled={loading}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                    value={values.description} // Use value prop instead of initialValue
                    onEditorChange={(content, editor) => {
                      setValues((prevState) => ({
                        ...prevState,
                        description: content,
                      }));
                    }}
                    init={{
                      // menubar: false,
                      // toolbar: false,
                      // statusbar: false,
                      // resize: false,
                      branding: false,
                      width: '100%',
                      height: 500,
                      menubar: true,
                      plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'help',
                        'wordcount',
                      ],

                      toolbar:
                        'undo redo | casechange blocks | bold italic backcolor | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-12">
              <div className="grid gap-8 p-5 bg-gray-100 sm:grid-cols-12 sm:gap-10 rounded-xl ">
                <div className="sm:col-span-2">
                  <Tooltip title="Enter detailed description of your product. This will be displayed on your store">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="w-3.5 h-3.5 text-gray-500 inline-flex mx-1"
                    />
                  </Tooltip>
                  <label
                    htmlFor="af-account-bio"
                    className="inline-block text-sm font-bold text-gray-800 "
                  >
                    Meta Description
                  </label>
                </div>

                <div className="sm:col-span-10">
                  <textarea
                    className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 "
                    rows={3}
                    placeholder="This will be used for SEO purposes. Max 160 characters"
                    value={values.metaDescription}
                    onChange={handleChange}
                    name="metaDescription"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* End Grid */}
          <div className="flex justify-end mt-5 gap-x-2">
            <button
              type="button"
              onClick={() => navigate('/panel/products')}
              className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
              className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 "
            >
              {loading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="w-5 h-6 animate-spin"
                />
              ) : (
                '  Save changes'
              )}
            </button>
          </div>
        </div>
        {/* End Card */}
      </div>
    </>
  );
};

export default ProductForm;
