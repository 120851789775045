import axios from 'axios';

export const listUsers = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/users`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const getOrders = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/orders`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const changeStatus = async (orderId, orderStatus, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_BASE_URL}/admin/order-status`,
    { orderId, orderStatus },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

export const getTransactions = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/transactions`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });
