import axios from 'axios';

export const getMaintenances = async () =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/maintenances`);

export const getMaintenance = async (slug) =>
  await axios.get(`${process.env.REACT_APP_BASE_URL}/maintenance/${slug}`);

export const removeMaintenance = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/maintenance/${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });

export const updateMaintenance = async (slug, maintenance, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_BASE_URL}/maintenance/${slug}`,
    maintenance,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

export const createMaintenance = async (maintenance, authtoken) =>
  await axios.post(
    `${process.env.REACT_APP_BASE_URL}/maintenance`,
    maintenance,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
