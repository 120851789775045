import React, { useState, useCallback, useEffect } from 'react';

import { getCoupons, removeCoupon } from 'redux/functions/coupon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faChevronRight,
  faChevronLeft,
  faMagnifyingGlass,
  faTrash,
  faClockRotateLeft,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import AddCoupoun from './Add';
import { useAuthStore } from 'store/authStore';

const Coupons = () => {
  const { user } = useAuthStore();
  const [coupons, setCoupons] = useState([]);
  const [keyword, setKeyword] = useState('');

  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };

  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);

  const fetchCoupoun = useCallback(async () => {
    getCoupons(user?.token)
      .then((res) => {
        setCoupons(res.data);
      })
      .catch((err) => {
        console.log(err.response.data?.errMsg);
      });
  }, [user?.token]);

  const handleRemove = (coupounId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      removeCoupon(coupounId, user.token)
        .then((res) => {
          fetchCoupoun();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            console.log(err.response.data?.errMsg);
          }
        });
    }
  };

  useEffect(() => {
    fetchCoupoun();
  }, [fetchCoupoun]);

  return (
    <div className="max-w-[85rem]  py-10  lg:py-14 mx-auto">
      {/* Card */}
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
              {/* Header */}
              <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                <div className="sm:col-span-1">
                  <label
                    htmlFor="hs-as-table-product-review-search"
                    className="sr-only"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="hs-as-table-product-review-search"
                      name="hs-as-table-product-review-search"
                      className="block w-full px-3 py-2 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="w-4 h-4 text-gray-400"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <AddCoupoun fetchData={fetchCoupoun} />
                  </div>
                </div>
              </div>
              {/* End Header */}
              {/* Table */}
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Coupon Code
                        </span>
                        <div className="flex items-center justify-center w-5 h-5 text-gray-400 border border-gray-200 rounded group-hover:bg-gray-200 ">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="w-2.5 h-2.5"
                          />
                        </div>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Discount %
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="inline-flex items-center cursor-pointer group gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Expiry Date
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-right" />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 ">
                  {coupons?.length > 0 ? (
                    <>
                      {coupons?.filter(searched(keyword))?.map((cop) => (
                        <tr
                          key={cop?._id}
                          className="bg-white hover:bg-gray-50 "
                        >
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="flex flex-col ">
                              <div className="px-6 ">
                                <span className="font-mono text-sm text-blue-600 ">
                                  {cop?.name}
                                </span>
                              </div>
                              <div className="px-6 pb-1">
                                <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-gray-100 text-gray-600 ">
                                  <FontAwesomeIcon
                                    icon={faClockRotateLeft}
                                    className="w-2.5 h-2.5"
                                  />
                                  {moment(cop?.createdAt).format('MMM Do YYYY')}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="relative z-10 block">
                              <div className="px-6 py-2">{cop?.discount}%</div>
                            </div>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="relative z-10 block">
                              <div className="px-6 py-2">
                                <span className="inline-flex items-center gap-1.5 py-1 px-2 rounded-md text-xs font-medium bg-gray-100 text-gray-800  ">
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="w-2.5 h-2.5"
                                  />
                                  {moment(cop?.expiry).format(
                                    'MMM Do YYYY hh:mm:ss a'
                                  )}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className="w-px h-px ">
                            <button
                              type="button"
                              onClick={() => handleRemove(cop?._id)}
                              className="flex items-center px-3 py-2 text-sm text-red-600 "
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="w-4 h-4"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="6" className="px-6 py-3 text-center">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-16 h-16 text-gray-300"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 2a8 8 0 100 16 8 8 0 000-16zM7 9a1 1 0 112 0v3a1 1 0 11-2 0V9zm6 0a1 1 0 112 0v3a1 1 0 11-2 0V9z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p className="text-gray-500">No Coupons Found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* End Table */}
              {/* Footer */}
              <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                <div>
                  <p className="text-sm text-gray-600 ">
                    <span className="font-semibold text-gray-800 ">
                      {coupons?.filter(searched(keyword))?.length}
                    </span>{' '}
                    results
                  </p>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="w-3 h-3"
                      />
                      Prev
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      Next
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="w-3 h-3"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        </div>
      </div>
      {/* End Card */}
    </div>
  );
};

export default Coupons;
