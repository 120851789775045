import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faClockRotateLeft,
  faCloudArrowDown,
  faDownload,
  faEllipsis,
  faFileCsv,
  faFileExcel,
  faFileInvoice,
  faFilePdf,
  faFilter,
  faInfoCircle,
  faMagnifyingGlass,
  faPrint,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { useAuthStore } from 'store/authStore';
import { getOrders } from 'redux/functions/admin';
import moment from 'moment';
import AmountInWords from 'utils/AmountInWords';
import TransactionDialog from './components/TransactionDialog';
const Transactions = () => {
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');
  const [invoice, setInvoice] = useState({}); // [{}]
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState([{}]);
  const [keyword, setKeyword] = useState('');

  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const searched = (keyword) => (c) =>
    c?.paymentDetails?.tracking_id?.toLowerCase().includes(keyword) ||
    c?.paymentDetails?.bank_ref_no?.toLowerCase().includes(keyword);

  const fetchOrders = useCallback(async () => {
    getOrders(user.token)
      .then((res) => {
        setOrders(res?.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user?.token]);

  const handleClickOpen = (invoice) => () => {
    setInvoice(invoice);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);
  return (
    <div className="max-w-[85rem]  py-10  lg:py-14 mx-auto">
      {/* Card */}
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
              {/* Header */}
              <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                {/* Input */}
                <div className="sm:col-span-1">
                  <label
                    htmlFor="hs-as-table-product-review-search"
                    className="sr-only"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="hs-as-table-product-review-search"
                      name="hs-as-table-product-review-search"
                      className="block w-full px-3 py-2 text-sm border-gray-200 rounded-md shadow-sm pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Search by id"
                      onChange={handleSearchChange}
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="w-4 h-4 text-gray-400"
                      />
                    </div>
                  </div>
                </div>
                {/* End Input */}
                <div className="sm:col-span-2 md:grow">
                  <div className="flex justify-end gap-x-2">
                    <div className="hs-dropdown relative inline-block [--placement:bottom-right]">
                      <button
                        id="hs-as-table-table-export-dropdown"
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          className="w-3 h-3"
                        />
                        Export
                      </button>
                      <div
                        className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden  divide-y divide-gray-200 min-w-[12rem] z-10 bg-white shadow-md rounded-lg p-2 mt-2  "
                        aria-labelledby="hs-as-table-table-export-dropdown"
                      >
                        <div className="py-2 first:pt-0 last:pb-0">
                          <span className="block px-3 py-2 text-xs font-medium text-gray-400 uppercase ">
                            Download options
                          </span>
                          <a
                            className="flex items-center px-3 py-2 text-sm text-gray-800 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                            href="/"
                          >
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              className="w-4 h-4"
                            />
                            Excel
                          </a>
                          <a
                            className="flex items-center px-3 py-2 text-sm text-gray-800 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                            href="/"
                          >
                            <FontAwesomeIcon
                              icon={faFileCsv}
                              className="w-4 h-4"
                            />
                            .CSV
                          </a>
                          <a
                            className="flex items-center px-3 py-2 text-sm text-gray-800 rounded-md gap-x-3 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 "
                            href="/"
                          >
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              className="w-4 h-4"
                            />
                            .PDF
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Header */}
              {/* Table */}
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <div className="pt-1 text-xs font-semibold tracking-wide text-gray-800 uppercase">
                          Tracking ID
                        </div>
                        <div className="hs-tooltip [--trigger:click]">
                          <div className="hs-tooltip-toggle">
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="w-3.5 h-3.5 hs-tooltip-toggle"
                            />
                            <span
                              className="absolute z-10 invisible inline-block max-w-xs px-2 py-1 text-xs font-medium text-white transition-opacity bg-gray-900 rounded-md shadow-sm opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible"
                              role="tooltip"
                            >
                              Tracking ID is a unique ID for each transaction.
                            </span>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Amount
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Status
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Customer
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-right" />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 ">
                  {orders?.length > 0 ? (
                    <>
                      {orders?.filter(searched(keyword))?.map((order) => (
                        <tr
                          key={order?._id}
                          className="bg-white hover:bg-gray-50 "
                        >
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="flex flex-col ">
                              <div className="flex flex-col px-6">
                                <span className="font-mono text-sm text-blue-600 ">
                                  Tracking ID:{' '}
                                  {order?.paymentDetails?.tracking_id}
                                </span>
                                <span className="font-mono text-sm text-blue-600 ">
                                  Bank Ref No:{' '}
                                  {order?.paymentDetails?.bank_ref_no}
                                </span>
                              </div>
                              <div className="px-6 pb-1">
                                <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-gray-100 text-gray-600 ">
                                  <FontAwesomeIcon
                                    icon={faClockRotateLeft}
                                    className="w-2.5 h-2.5"
                                  />
                                  {order?.paymentDetails?.trans_date}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="block">
                              <div className="flex flex-col items-start justify-start px-6 py-2 ">
                                <div className="flex flex-col items-center justify-start">
                                  <div className="flex items-center justify-center text-sm">
                                    {Number(
                                      order?.paymentDetails?.amount
                                    )?.toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'INR',
                                    })}
                                    <div className="hs-tooltip [--trigger:click] inline-flex items-center gap-1.5 py-0.5 px-2">
                                      <FontAwesomeIcon
                                        icon={faCircleQuestion}
                                        className="w-3 h-3 text-gray-800 hs-tooltip-toggle"
                                      />
                                      <span
                                        className="absolute z-10 invisible block px-2 py-1 text-xs font-medium text-white transition-opacity bg-gray-900 rounded-md shadow-sm opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible "
                                        role="tooltip"
                                      >
                                        <AmountInWords
                                          amount={Number(
                                            order?.paymentDetails?.amount
                                          )}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-sm text-gray-600 ">
                                  {order?.paymentDetails?.payment_mode}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="block">
                              <div className="px-6 py-2">
                                {order?.paymentStatus === 'completed' ? (
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-600 ">
                                    Completed
                                  </span>
                                ) : order?.paymentStatus === 'initiated' ||
                                  order?.paymentStatus === 'failed' ? (
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-red-100 text-red-600 ">
                                    Failed
                                  </span>
                                ) : order?.paymentStatus === 'cancelled' ? (
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-yellow-100 text-yellow-600 ">
                                    Cancelled
                                  </span>
                                ) : (
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-blue-100 text-blue-600 ">
                                    Refunded
                                  </span>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              <div className="flex items-center gap-x-3">
                                <div className="grow">
                                  <span className="block text-sm font-semibold text-gray-800 ">
                                    {order?.paidBy?.name}
                                  </span>
                                  <span className="block text-sm text-gray-500">
                                    {order?.paidBy?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="w-px h-px whitespace-nowrap">
                            {order?.paymentStatus === 'initiated' ||
                            order?.paymentStatus === 'failed' ? null : (
                              <div className="block">
                                <div className="px-6 py-1.5">
                                  <button
                                    type="button"
                                    onClick={handleClickOpen(order)}
                                    className="inline-flex items-center justify-center gap-2 px-2 py-1 text-sm font-medium text-gray-700 align-middle bg-white border rounded-md shadow-sm hover:bg-gray-50 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faFileInvoice}
                                      className="w-4 h-4"
                                    />
                                    View
                                  </button>
                                  <button className="inline-flex items-center justify-center gap-2 px-2 py-1 text-sm font-medium text-gray-700 align-middle ">
                                    <FontAwesomeIcon
                                      icon={faCloudArrowDown}
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td className="px-6 py-4 text-center" colSpan="6">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-16 h-16 text-gray-300"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 2a8 8 0 100 16 8 8 0 000-16zM7 9a1 1 0 112 0v3a1 1 0 11-2 0V9zm6 0a1 1 0 112 0v3a1 1 0 11-2 0V9z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p className="text-gray-500">No Orders Found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* End Table */}
              {/* Footer */}
              <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                <div>
                  <p className="text-sm text-gray-600 ">
                    <span className="font-semibold text-gray-800 ">
                      {orders?.filter(searched(keyword))?.length}
                    </span>{' '}
                    results
                  </p>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="w-3 h-3"
                      />
                      Prev
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                    >
                      Next
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="w-3 h-3"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        </div>
      </div>
      <TransactionDialog
        open={open}
        handleClose={handleClose}
        invoice={invoice}
      />
      {/* End Card */}
    </div>
  );
};

export default Transactions;
