import React, { useState, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { createMaintenance } from 'redux/functions/maintenance';
import { useAuthStore } from 'store/authStore';

export default function AddCategory({ fetchData }) {
  const { user } = useAuthStore();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    maintenance: '',
  });

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        await createMaintenance(formData, user.token);
        setFormData({
          maintenance: '',
        });
        setLoading(false);
        setOpen(false);
        fetchData();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [formData, user?.token]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        onClick={handleClickOpen}
        type="button"
        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 "
      >
        <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4" />
        Add Maintenance
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Maintenance</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add new maintenance, please enter the maintenance name here.
          </DialogContentText>
          <input
            id="af-maintenance-name"
            type="text"
            autoFocus
            name="maintenance"
            value={formData.maintenance}
            onChange={handleChange}
            className="relative block w-full px-3 py-2 -mt-px -ml-px text-sm border-gray-200 rounded-lg shadow-sm pr-11 sm:mt-0 sm:first:ml-0 focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
            placeholder="Enter Maintenance name"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>
            {loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="w-4 h-4 mr-2 -ml-1 animate-spin"
              />
            ) : (
              'Add Maintenance'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
