import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const Visitors = () => {
  return (
    <>
      {/* Table Section */}
      <div className="py-10 lg:py-14">
        {/* Card */}
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl ">
                {/* Header */}
                <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center ">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 ">
                      Visitors
                    </h2>
                    <p className="text-sm text-gray-600 ">
                      Visitors overview by country.
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <a
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                        href="/"
                      >
                        View all
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Header */}
                {/* Table */}
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="divide-y divide-gray-200 bg-gray-50 ">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left border-l border-gray-200 "
                      >
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Country
                        </span>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Users
                        </span>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          New Users
                        </span>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <span className="text-xs font-semibold tracking-wide text-gray-800 uppercase ">
                          Sessions
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 ">
                    <tr>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="flex items-center px-6 py-2 gap-x-3">
                          <span className="text-sm text-gray-600 ">1.</span>
                          <a className="flex items-center gap-x-2" href="/">
                            <img
                              src="https://flagsapi.com/CN/flat/64.png"
                              className="w-5 h-5"
                              alt=""
                            />
                            <span className="text-sm text-blue-600 decoration-2 hover:underline ">
                              China
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm font-semibold text-gray-800 ">
                            195
                          </span>
                          <span className="text-xs text-gray-500">
                            (23.16%)
                          </span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">172</span>
                          <span className="text-xs text-gray-500">
                            (24.50%)
                          </span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">208</span>
                          <span className="text-xs text-gray-500">
                            (21.67%)
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="flex items-center px-6 py-2 gap-x-3">
                          <span className="text-sm text-gray-600 ">2.</span>
                          <a className="flex items-center gap-x-2" href="/">
                            <img
                              src="https://flagsapi.com/IN/flat/64.png"
                              className="w-5 h-5"
                              alt=""
                            />
                            <span className="text-sm text-blue-600 decoration-2 hover:underline ">
                              India
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm font-semibold text-gray-800 ">
                            99
                          </span>
                          <span className="text-xs text-gray-500">
                            (11.76%)
                          </span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">80</span>
                          <span className="text-xs text-gray-500">
                            (11.40%)
                          </span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">111</span>
                          <span className="text-xs text-gray-500">
                            (11.56%)
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="flex items-center px-6 py-2 gap-x-3">
                          <span className="text-sm text-gray-600 ">3.</span>
                          <a className="flex items-center gap-x-2" href="/">
                            <img
                              src="https://flagsapi.com/US/flat/64.png"
                              className="w-5 h-5"
                              alt=""
                            />
                            <span className="text-sm text-blue-600 decoration-2 hover:underline ">
                              United States
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm font-semibold text-gray-800 ">
                            96
                          </span>
                          <span className="text-xs text-gray-500">
                            (11.40%)
                          </span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">82</span>
                          <span className="text-xs text-gray-500">
                            (11.68%)
                          </span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">108</span>
                          <span className="text-xs text-gray-500">
                            (11.25%)
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="flex items-center px-6 py-2 gap-x-3">
                          <span className="text-sm text-gray-600 ">4.</span>
                          <a className="flex items-center gap-x-2" href="/">
                            <img
                              src="https://flagsapi.com/TR/flat/64.png"
                              className="w-5 h-5"
                              alt=""
                            />
                            <span className="text-sm text-blue-600 decoration-2 hover:underline ">
                              Turkey
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm font-semibold text-gray-800 ">
                            32
                          </span>
                          <span className="text-xs text-gray-500">(3.80%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">28</span>
                          <span className="text-xs text-gray-500">(3.99%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">38</span>
                          <span className="text-xs text-gray-500">(3.96%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="flex items-center px-6 py-2 gap-x-3">
                          <span className="text-sm text-gray-600 ">5.</span>
                          <a className="flex items-center gap-x-2" href="/">
                            <img
                              src="https://flagsapi.com/DE/flat/64.png"
                              className="w-5 h-5"
                              alt=""
                            />
                            <span className="text-sm text-blue-600 decoration-2 hover:underline ">
                              Germany
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm font-semibold text-gray-800 ">
                            25
                          </span>
                          <span className="text-xs text-gray-500">(2.97%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">22</span>
                          <span className="text-xs text-gray-500">(3.13%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">31</span>
                          <span className="text-xs text-gray-500">(3.23%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="flex items-center px-6 py-2 gap-x-3">
                          <span className="text-sm text-gray-600 ">6.</span>
                          <a className="flex items-center gap-x-2" href="/">
                            <img
                              src="https://flagsapi.com/CA/flat/64.png"
                              className="w-5 h-5"
                              alt=""
                            />
                            <span className="text-sm text-blue-600 decoration-2 hover:underline ">
                              Canada
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm font-semibold text-gray-800 ">
                            24
                          </span>
                          <span className="text-xs text-gray-500">(2.85%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">22</span>
                          <span className="text-xs text-gray-500">(3.13%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">26</span>
                          <span className="text-xs text-gray-500">(2.71%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="flex items-center px-6 py-2 gap-x-3">
                          <span className="text-sm text-gray-600 ">7.</span>
                          <a className="flex items-center gap-x-2" href="/">
                            <img
                              src="https://flagsapi.com/FR/flat/64.png"
                              className="w-5 h-5"
                              alt=""
                            />
                            <span className="text-sm text-blue-600 decoration-2 hover:underline ">
                              France
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm font-semibold text-gray-800 ">
                            46
                          </span>
                          <span className="text-xs text-gray-500">(2.78%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">19</span>
                          <span className="text-xs text-gray-500">(2.67%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">19</span>
                          <span className="text-xs text-gray-500">(2.67%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="flex items-center px-6 py-2 gap-x-3">
                          <span className="text-sm text-gray-600 ">8.</span>
                          <a className="flex items-center gap-x-2" href="/">
                            <img
                              src="https://flagsapi.com/BR/flat/64.png"
                              className="w-5 h-5"
                              alt=""
                            />
                            <span className="text-sm text-blue-600 decoration-2 hover:underline ">
                              Brazil
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm font-semibold text-gray-800 ">
                            42
                          </span>
                          <span className="text-xs text-gray-500">(2.54%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">16</span>
                          <span className="text-xs text-gray-500">(1.54%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">15</span>
                          <span className="text-xs text-gray-500">(1.56%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="flex items-center px-6 py-2 gap-x-3">
                          <span className="text-sm text-gray-600 ">9.</span>
                          <a className="flex items-center gap-x-2" href="/">
                            <img
                              src="https://flagsapi.com/GB/flat/64.png"
                              className="w-5 h-5"
                              alt=""
                            />
                            <span className="text-sm text-blue-600 decoration-2 hover:underline ">
                              United Kingdom
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm font-semibold text-gray-800 ">
                            30
                          </span>
                          <span className="text-xs text-gray-500">(1.93%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">14</span>
                          <span className="text-xs text-gray-500">(1.30%)</span>
                        </div>
                      </td>
                      <td className="w-auto h-px whitespace-nowrap">
                        <div className="px-6 py-2">
                          <span className="text-sm text-gray-800 ">11</span>
                          <span className="text-xs text-gray-500">(1.22%)</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* End Table */}
                {/* Footer */}
                <div className="grid gap-3 px-6 py-4 border-t border-gray-200 md:flex md:justify-between md:items-center ">
                  <div>
                    <p className="text-sm text-gray-600 ">
                      <span className="font-semibold text-gray-800 ">9</span>{' '}
                      results
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="w-3 h-3"
                        />
                        Prev
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 "
                      >
                        Next
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="w-3 h-3"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                {/* End Footer */}
              </div>
            </div>
          </div>
        </div>
        {/* End Card */}
      </div>
      {/* End Table Section */}
    </>
  );
};

export default Visitors;
