import { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
// react-router components
import { useLocation, useRoutes } from 'react-router-dom';
// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
//  React examples
import Sidenav from 'examples/Sidenav';

//  React themes
import theme from 'assets/theme';

//  React routes
import { routes, sidenavRoutes } from 'routes';

//  React contexts
import { useSoftUIController, setMiniSidenav } from 'context';

// Images
import brand from 'assets/logo.png';

export default function App() {
  const routing = useRoutes(routes);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: '14px',
            background: '#fff',
            color: '#002D4C',
            zIndex: 99,
          },
          duration: 3000,
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: '#fff',
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: 'red',
              secondary: '#fff',
            },
          },
        }}
      />
      {layout === 'dashboard' && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Admin Panel"
            routes={sidenavRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      {routing}
    </ThemeProvider>
  );
}
